import { tracked } from '@glimmer/tracking';
import Crc32 from 'editor/utils/crc32';
import promiseBatch from 'editor/utils/promise-batch';
import Reader from 'editor/utils/reader';
import Writer from 'editor/utils/writer';

class DrumsetHeader {
  type: string = 'BBds';
  version: number = 1;
  revision: number = 1;
  build: number = 5697;
  crc: number = 0;
}

export interface IInstrument {
  id: number;
  name: string;
  nonPercussion: number;
  chokeGroup: number;
  fillChokeGroup: number;
  fillChokeDelay: number;
  volume: number;
  poly: number;
  volumeDb: number;
  markDirty(): void;
}

export class Instrument implements IInstrument {
  @tracked id: number = 0;
  @tracked name: string = '';
  @tracked chokeGroup: number = 0;
  @tracked poly: number = 0;
  dataSize: number = 0;
  @tracked volume: number = 100;
  @tracked fillChokeGroup: number = 0;
  @tracked fillChokeDelay: number = 0;
  @tracked nonPercussion: number = 0;
  @tracked _velocities: Velocity[] = [];
  _isDirty: boolean = false;

  constructor(id: number, name: string = 'Unknown') {
    this.id = id;
    this.name = name;
  }

  get isDirty() {
    return this._isDirty;
  }

  markDirty() {
    this._isDirty = true;
  }

  markClean() {
    this._isDirty = false;
  }

  get size() {
    return this.#nameSize + this.velocities.reduce((a, b) => a + b.size, 0);
  }

  get isValid(): true | string {
    if (this.velocities.some((v) => !v.data)) {
      return 'Missing samples';
    }
    if (this.velocities.length > 16) {
      return 'Too many samples (max 16)';
    }
    return true;
  }

  get #nameSize() {
    if (this.name === '') return 0;
    return 4 + this.name.length;
  }

  get velocities() {
    return this._velocities;
  }

  set velocities(velocities: Velocity[]) {
    this._velocities = velocities.toSorted((v1, v2) => v1.vel - v2.vel);
  }

  sortVelocities() {
    this._velocities = this._velocities.toSorted((v1, v2) => v1.vel - v2.vel);
  }

  addVelocities(velocities: Velocity[]) {
    this.velocities = [...this._velocities, ...velocities];
  }

  removeVelocities(velocities: Velocity[]) {
    this.velocities = this._velocities.filter(
      (velocity) => !velocities.includes(velocity),
    );
  }

  cleanVelocities() {
    this._velocities = this._velocities.filter((velocity) => velocity.data);
  }

  get volumeDb() {
    // return volume to db where 100 is 0dB and 0 is -40dB
    return Number((20 * Math.log10(this.volume / 100)).toFixed(1));
  }

  copy() {
    const instrument = new Instrument(this.id, this.name);
    instrument.chokeGroup = this.chokeGroup;
    instrument.poly = this.poly;
    instrument.volume = this.volume;
    instrument.fillChokeGroup = this.fillChokeGroup;
    instrument.fillChokeDelay = this.fillChokeDelay;
    instrument.nonPercussion = this.nonPercussion;
    instrument._velocities = this._velocities.map((velocity) =>
      velocity.copy(),
    );
    return instrument;
  }
}

export class Velocity {
  fileName?: string;
  bps: number = 0;
  nChannel: number = 0;
  fs: number = 0;
  @tracked vel: number = 0;
  nSample: number = 0;
  offset: number = 0;
  data?: ArrayBuffer;
  sample?: ArrayBuffer;
  crc: number = 0;
  @tracked audio?: AudioBuffer;

  constructor(vel: number = 0) {
    this.vel = vel;
  }

  get name() {
    return this.fileName
      ?.split('/')
      ?.pop()
      ?.replace(/\.wav$/i, '');
  }

  get size() {
    if (this.data) {
      const dataSize = this.data.byteLength;
      const padding = 512 - (dataSize % 512);
      const fileNameSize = this.fileName?.length ?? 0 + 4;
      return dataSize + padding + fileNameSize;
    } else {
      return 0;
    }
  }

  _loudness?: number;
  get loudness() {
    if (this._loudness !== undefined) {
      return this._loudness;
    }

    if (!this.audio) {
      return 0;
    }

    this._loudness = calculateLoudness(this.audio);
    return this._loudness;
  }

  get duration() {
    return this.audio?.duration ?? 0;
  }

  copy() {
    const velocity = new Velocity(this.vel);
    velocity.fileName = this.fileName;
    velocity.bps = this.bps;
    velocity.nChannel = this.nChannel;
    velocity.fs = this.fs;
    velocity.nSample = this.nSample;
    velocity.offset = this.offset;
    velocity.data = this.data;
    velocity.sample = this.sample;
    velocity.crc = this.crc;
    velocity.audio = this.audio;
    return velocity;
  }
}

function calculateLoudness(audioBuffer: AudioBuffer) {
  const channels = audioBuffer.numberOfChannels;
  const peak = Math.max(
    ...Array.from({ length: channels }).map((_, c) => {
      const channel = audioBuffer.getChannelData(c);
      const peak = channel.reduce(
        (max, val) => Math.max(max, Math.abs(val)),
        0,
      );
      return peak;
    }),
  );
  const loudness = 20 * Math.log10(peak); // convert to dB
  return loudness;
}

class DrumsetOffsets {
  metaDataOffset: number = 0;
  metaDataSize: number = 0;
  extensionsHeader: string = 'exts';
  volumeOffset: number = 0;
  volumeSize: number = 0;
}

class DrumsetInfo {
  @tracked name: string = '';
  @tracked volume: number = 100;
}

export default class Drumset {
  header: DrumsetHeader = new DrumsetHeader();
  @tracked instruments: Instrument[] = new Array(128)
    .fill(0)
    .map((_, i) => new Instrument(i));
  offsets: DrumsetOffsets = new DrumsetOffsets();
  info: DrumsetInfo = new DrumsetInfo();
  _isDirty = false;

  get isDirty() {
    return this._isDirty || this.instruments.some((i) => i.isDirty);
  }

  markDirty() {
    this._isDirty = true;
  }

  markClean() {
    this._isDirty = false;
    this.instruments.forEach((i) => i.markClean());
  }

  get name() {
    return this.info.name;
  }

  set name(value: string) {
    this.info.name = value;
  }

  get volume() {
    return this.info.volume;
  }

  set volume(value: number) {
    this.info.volume = value;
  }

  get volumeDb() {
    // return volume to db where 100 is 0dB and 0 is -40dB
    return Number((20 * Math.log10(this.volume / 100)).toFixed(1));
  }

  get size(): number {
    const headerInfo = 60416;
    const samplesSize = this.instruments.reduce((sum, i) => sum + i.size, 0);
    return headerInfo + samplesSize;
  }

  get isValid(): true | string {
    if (this.size > 1024 * 1024 * 100) {
      return 'Drumset file size is too large (max 100 MB)';
    }
    if (this.playableInstruments.length === 0) {
      return 'No instruments';
    }
    for (let i = 0; i < 128; i++) {
      const instrument = this.instruments[i]!;
      const valid = instrument.isValid;
      if (valid !== true) {
        return `${instrument.name}: ${valid}`;
      }
    }
    return true;
  }

  get playableInstruments() {
    return this.instruments
      .filter((instrument) => {
        return instrument.velocities.length > 0;
      })
      .sort((a, b) => a.id - b.id)
      .reverse();
  }

  get availableInstruments() {
    return this.instruments
      .filter((instrument) => {
        return instrument.velocities.length === 0;
      })
      .sort((a, b) => a.id - b.id);
  }

  prepareAudio(audioContext: AudioContext) {
    const velocities = this.instruments.flatMap((instrument) =>
      instrument.velocities.flatMap((velocity) => velocity),
    );

    promiseBatch(velocities, 4, async (velocity) => {
      if (velocity.audio || velocity.sample?.detached) {
        return;
      }

      return audioContext.decodeAudioData(velocity.sample).then((audio) => {
        velocity.audio = audio;
      });
    });
  }

  save() {
    const headerLength = 12;
    let offsetsLength = 4; // metaDataOffset
    offsetsLength += 4; // metaDataSize
    if (
      (this.header.version === 1 && this.header.revision === 1) ||
      this.header.version > 1
    ) {
      offsetsLength += 4 * 9;
    }

    const instrumentsLength = 59904;

    const waveOffset = headerLength + instrumentsLength + offsetsLength;
    const waveOffsets: number[] = [];
    const wavesWriter = saveDrumsetWaves(this, waveOffset, waveOffsets);
    const instrumentsWriter = saveDrumsetInstruments(this, waveOffsets);

    const infoWriter = saveDrumsetInfo(this);

    const volumeWriter = saveDrumsetVolume(this);

    const offsetWriter = new Writer();
    offsetWriter.setUint(infoWriter.offset);
    if (
      (this.header.version === 1 && this.header.revision === 1) ||
      this.header.version > 1
    ) {
      offsetWriter.setString('exth', false);
      offsetWriter.setUint(
        headerLength +
          instrumentsLength +
          offsetsLength +
          wavesWriter.offset +
          infoWriter.offset,
      );
      offsetWriter.setUint(volumeWriter.offset);
      offsetWriter.setUint(0); // reserved
      offsetWriter.setUint(0); // reserved
      offsetWriter.setUint(0); // reserved
      offsetWriter.setUint(0); // reserved
      offsetWriter.setUint(0); // reserved
      offsetWriter.setUint(0); // reserved
    }
    const offsets = offsetWriter.close();

    const crc32 = new Crc32();
    crc32.update(offsetWriter.buffer, offsetWriter.offset);
    crc32.update(instrumentsWriter.buffer, instrumentsWriter.offset);
    crc32.update(offsetWriter.buffer, offsetWriter.offset);
    crc32.update(wavesWriter.buffer, wavesWriter.offset);
    crc32.update(infoWriter.buffer, infoWriter.offset);
    crc32.update(volumeWriter.buffer, volumeWriter.offset);
    const crc = crc32.getCRC(true);
    this.header.crc = crc;

    const headerWriter = saveDrumsetHeader(this);
    const header = headerWriter.close();
    const instruments = instrumentsWriter.close();
    const waves = wavesWriter.close();
    const info = infoWriter.close();
    const volume = volumeWriter.close();

    const drumsetWriter = new Writer();
    drumsetWriter.append(header);
    drumsetWriter.append(instruments);
    drumsetWriter.setUint(
      header.byteLength + instrumentsLength + offsetsLength + waves.byteLength,
    );
    drumsetWriter.append(offsets);
    drumsetWriter.append(waves);
    drumsetWriter.append(info);
    drumsetWriter.append(volume);
    return drumsetWriter.close();
  }

  copy() {
    const data = this.save();
    const drumset = Drumset.parse(data);
    drumset.name = `${this.name} Copy`;
    return drumset;
  }

  static parseHeader(buffer: ArrayBuffer) {
    const reader = new Reader(new DataView(buffer), 0);
    return parseDrumsetHeader(reader);
  }

  static parse(buffer: ArrayBuffer) {
    const reader = new Reader(new DataView(buffer), 0);
    const drumset = new Drumset();
    drumset.header = parseDrumsetHeader(reader);
    drumset.instruments = parseDrumsetInstruments(reader);
    drumset.offsets = parseDrumsetOffsets(reader);
    drumset.info = parseDrumsetInfo(reader, drumset);
    parseDrumsetWaves(reader, drumset);
    return drumset;
  }

  // This parses a WAV file and returns a Velocity object
  // The WAV file can be in any format, mono or stereo, 16 or 24 bit, sample rate
  // The WAV file will be converted to 16bit, 44100Hz, mono/stereo for the BeatBuddy
  static async parseWaveFile(buffer: ArrayBuffer) {
    const velocity = new Velocity();
    const reader = new Reader(new DataView(buffer), 0);
    const groupId = reader.getString(4);
    if (groupId !== 'RIFF') {
      throw new Error('Invalid wave file');
    }
    const size = reader.getUint();
    const riffType = reader.getString(4);
    const chunks = readWaveChunks(reader);

    const fmtChunk = chunks.get('fmt ');
    if (!fmtChunk) {
      throw new Error('Invalid wave file');
    }
    const fmtReader = new Reader(new DataView(fmtChunk), 0);
    const audioFormat = fmtReader.getShort();

    const numberOfChannels = fmtReader.getShort();
    velocity.nChannel = numberOfChannels;

    const sampleRate = fmtReader.getUint();
    velocity.fs = sampleRate;

    const byteRate = fmtReader.getUint();

    const blockAlign = fmtReader.getShort();

    const bitsPerSample = fmtReader.getShort();
    velocity.bps = bitsPerSample;

    const dataChunk = chunks.get('data');
    if (!dataChunk) {
      throw new Error('Invalid wave file');
    }

    velocity.nSample = dataChunk.byteLength / (bitsPerSample / 8);

    velocity.sample = buffer;

    const audioContext = new OfflineAudioContext({
      numberOfChannels: numberOfChannels,
      length: dataChunk.byteLength / (bitsPerSample / 8) / numberOfChannels,
      sampleRate: 44100,
    });
    const audio = await audioContext.decodeAudioData(buffer);
    const writer = new Writer(dataChunk.byteLength);
    saveWaveSamples(audio, writer);
    velocity.bps = 16;
    velocity.fs = 44100;
    velocity.data = writer.close();
    velocity.crc = new Crc32()
      .update(velocity.data, velocity.data.byteLength)
      .getCRC();
    velocity.audio = audio;

    return velocity;
  }
  static saveWaveFile(audio: AudioBuffer) {
    const sampleRate = audio.sampleRate;
    const bitsPerSample = 16;
    const numberOfChannels = audio.numberOfChannels;

    const length = audio.length * (bitsPerSample / 8) * numberOfChannels;

    const writer = new Writer(length + 44);
    writer.setString('RIFF', false);
    writer.setUint(length + 36);
    writer.setString('WAVE', false);
    // Chunk header
    writer.setString('fmt ', false);
    // Chunk size
    writer.setUint(16);
    // Audio format
    writer.setShort(1);
    // Channel count
    writer.setShort(numberOfChannels);
    // Sample rate
    writer.setUint(sampleRate);
    // Bytes per sample
    writer.setUint(audio.sampleRate * 2 * numberOfChannels);
    // Block align
    writer.setShort(numberOfChannels * 2);
    // Bits per sample
    writer.setShort(bitsPerSample);
    // Data header
    writer.setString('data', false);
    // Data size
    writer.setUint(length);
    // Data

    saveWaveSamples(audio, writer);
    return writer.close();
  }
}

function saveWaveSamples(audio: AudioBuffer, writer: Writer) {
  // write interleaved data
  const numberOfChannels = audio.numberOfChannels;
  const channels: Float32Array[] = [];
  let offset = 0;
  for (let i = 0; i < numberOfChannels; i++) {
    channels.push(audio.getChannelData(i));
  }

  while (!writer.eof) {
    for (let i = 0; i < numberOfChannels; i++) {
      // interleave channels
      let sample = Math.max(-1, Math.min(1, channels[i]![offset]!)); // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0;
      writer.setInt16(sample, true); // update data chunk
    }
    offset++;
  }
  return writer;
}

function readWaveChunks(reader: Reader) {
  const chunks = new Map<string, ArrayBuffer>();
  while (!reader.eof && reader.remaining > 8) {
    const chunkId = reader.getString(4);
    const chunkSize = reader.getUint();
    const chunk = reader.getBytes(chunkSize);
    chunks.set(chunkId, chunk);
  }
  return chunks;
}

function parseDrumsetOffsets(reader: Reader) {
  const offsets = new DrumsetOffsets();
  offsets.metaDataOffset = reader.getUint();
  offsets.metaDataSize = reader.getUint();
  offsets.extensionsHeader = reader.getString(4);
  offsets.volumeOffset = reader.getUint();
  offsets.volumeSize = reader.getUint();
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  return offsets;
}

function parseDrumsetHeader(reader: Reader) {
  const header = new DrumsetHeader();
  header.type = reader.getString(4);
  header.version = reader.getByte();
  header.revision = reader.getByte();
  header.build = reader.getUShort();
  header.crc = reader.getUint();
  return header;
}

function saveDrumsetHeader(drumset: Drumset) {
  const header = drumset.header;
  const writer = new Writer(16);
  writer.setString(header.type, false);
  writer.setByte(header.version);
  writer.setByte(header.revision);
  writer.setUShort(header.build);
  writer.setUint(header.crc);
  return writer;
}

function parseDrumsetInstruments(reader: Reader) {
  const instruments: Instrument[] = [];
  for (let i = 0; i < 128; i++) {
    instruments.push(parseDrumsetInstrument(reader, i));
  }
  return instruments;
}

function parseDrumsetInstrument(reader: Reader, id: number) {
  const instrument = new Instrument(id);
  instrument.chokeGroup = reader.getUShort();
  instrument.poly = reader.getUShort();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _nVel = reader.getUint();
  instrument.dataSize = reader.getUint();
  instrument.volume = reader.getByte();
  instrument.fillChokeGroup = reader.getByte();
  instrument.fillChokeDelay = reader.getByte();
  instrument.nonPercussion = reader.getByte();
  reader.getUint(); // reserved
  const velocities = [];
  for (let i = 0; i < 16; i++) {
    const val = new Velocity();
    val.bps = reader.getUShort(); // Bits per sample
    val.nChannel = reader.getUShort(); // Number of channel (mono/stereo)
    val.fs = reader.getUint(); // Sampling frequency
    val.vel = reader.getUint(); // Lower bound velocity
    val.nSample = reader.getUint(); // Number of sample in the wav
    reader.getUint(); // Reserved
    reader.getUint(); // Reserved
    val.offset = reader.getUint(); // Offset [from start of file]
    if (val.bps !== 0) {
      velocities.push(val);
    }
  }
  instrument.velocities = velocities;
  if (velocities.length) {
    if (instrument.volume === 0) {
      instrument.volume = 100;
    }
    if (instrument.volume > 100) {
      instrument.volume = 100;
    }
  }
  return instrument;
}

function saveDrumsetInstruments(drumset: Drumset, offsets: number[]) {
  const writer = new Writer();
  drumset.instruments.forEach((instrument, i) => {
    let offset = instrument.velocities.length > 0 ? offsets.shift() ?? 0 : 0;
    writer.setUShort(instrument.chokeGroup);
    writer.setUShort(instrument.poly);
    writer.setUint(instrument.velocities.length);
    writer.setUint(instrument.dataSize);
    writer.setByte(instrument.volume);
    writer.setByte(instrument.fillChokeGroup);
    writer.setByte(instrument.fillChokeDelay);
    writer.setByte(instrument.nonPercussion);
    writer.setUint(0); // reserved
    for (let i = 0; i < 16; i++) {
      const velocity = instrument.velocities[i];
      writer.setUShort(velocity?.bps ?? 0);
      writer.setUShort(velocity?.nChannel ?? 0);
      writer.setUint(velocity?.fs ?? 0);
      writer.setUint(velocity?.vel ?? 0);
      writer.setUint(velocity?.nSample ?? 0);
      writer.setUint(0); // reserved
      writer.setUint(0); // reserved
      writer.setUint(velocity ? offset : 0);
      if (velocity) {
        offset += velocity?.data?.byteLength ?? 0;
      }
    }
  });
  return writer;
}

function parseDrumsetInfo(reader: Reader, drumset: Drumset) {
  const info = new DrumsetInfo();
  reader.seek(drumset.offsets.metaDataOffset);
  info.name = reader.streamString(false);
  drumset.instruments.forEach((instrument, i) => {
    if (instrument.velocities.length > 0) {
      instrument.name = reader.streamString(false);
    }
  });
  drumset.instruments.forEach((instrument) => {
    instrument.velocities.forEach((velocity) => {
      if (velocity.nSample !== 0) {
        velocity.fileName = reader.streamString(false);
      }
    });
  });

  if (drumset.offsets.volumeOffset) {
    reader.seek(drumset.offsets.volumeOffset);
    const type = reader.getString(4); // "volg"
    // Some drumsets have a volume offset but no volume data
    if (type === 'volg') {
      info.volume = reader.getUint(false);
    }
  }
  return info;
}

function saveDrumsetInfo(drumset: Drumset) {
  const writer = new Writer();
  writer.streamString(drumset.info.name, false);
  drumset.instruments.forEach((instrument) => {
    if (instrument.velocities.length > 0) {
      writer.streamString(instrument.name, false);
    }
  });
  drumset.instruments.forEach((instrument) => {
    instrument.velocities.forEach((velocity) => {
      if (velocity.fileName) {
        writer.streamString(velocity.fileName, false);
      }
    });
  });
  writer.setUint(0); // reserved
  return writer;
}

function saveDrumsetVolume(drumset: Drumset) {
  const writer = new Writer();
  writer.setString('volg', false);
  writer.setUint(drumset.info.volume, false);
  return writer;
}

function parseDrumsetWaves(reader: Reader, drumset: Drumset) {
  drumset.instruments.forEach((instrument) => {
    instrument.velocities.forEach((velocity) => {
      const bitsPerSample = velocity.bps;
      const numberChannels = velocity.nChannel;
      const sampleRate = velocity.fs;
      const rawSize = (bitsPerSample / 8) * velocity.nSample;
      const data = reader.seek(velocity.offset).getBytes(rawSize);
      const writer = new Writer(rawSize + 36);
      writer.setString('RIFF', false);
      writer.setUint(rawSize + 36);
      writer.setString('WAVE', false);
      // Chunk header
      writer.setString('fmt ', false);
      // Chunk size
      writer.setUint(16);
      // Audio format
      writer.setShort(1);
      // Channel count
      writer.setShort(numberChannels);
      // Sample rate
      writer.setUint(sampleRate);
      // Bytes per sample
      writer.setUint(
        (sampleRate * bitsPerSample * numberChannels * bitsPerSample) / 8,
      );
      // Block align
      writer.setShort((bitsPerSample * numberChannels) / 8);
      // Bits per sample
      writer.setShort(bitsPerSample);
      // Data header
      writer.setString('data', false);
      // Data size
      writer.setUint(rawSize);
      // Data
      writer.append(data);
      // We need both the raw data and the sample because when we decode the sample
      //  to an audio buffer, we lose the raw data—it gets detached.
      //  the alternative is to copy the raw data into the sample, but that
      //  adds copy overhead at the time when we need to decode the sample and play.
      //  see: https://github.com/WebAudio/web-audio-api/issues/1175
      velocity.data = data;
      velocity.sample = writer.close();
    });
  });
}

function saveDrumsetWaves(
  drumset: Drumset,
  waveOffset: number,
  offsets: number[],
) {
  const writer = new Writer();
  let offset = 60416;
  // Offset the difference between the hard coded offset and the actual offset
  writer.offset += offset - waveOffset;
  let padding = 0;
  drumset.instruments.forEach((instrument) => {
    instrument.cleanVelocities();
    instrument.dataSize = 0;
    if (instrument.velocities.length > 0) {
      writer.offset += padding;
      offset += padding;
      offsets.push(offset);
      instrument.velocities.forEach((velocity) => {
        instrument.dataSize += velocity.data!.byteLength;
        writer.append(velocity.data!);
        offset += velocity.data!.byteLength;
      });
      padding = 512 - (instrument.dataSize % 512);
    }
  });
  return writer;
}
