import { action } from '@ember/object';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Drumset, { Instrument } from 'editor/models/drumset';
import Song, {
  PartType,
  SectionType,
  SongPart,
  SongTrack,
} from 'editor/models/song';
import elementExists from 'editor/utils/element-exists';
import playAccent from 'editor/utils/play-accent';
import playInstrument from 'editor/utils/play-instrument';
import ProjectManagerService, { EffectFile } from './project-manager';
import type VirtualPedalService from './virtual-pedal';

export class SelectedTrack {
  part: SongPart;
  sectionType: SectionType;
  sectionIndex: number = 0;
  track: SongTrack;

  constructor(part: SongPart, sectionType: SectionType, index: number) {
    this.part = part;
    this.sectionType = sectionType;
    this.sectionIndex = index;
    this.track = this.part.getTrack(this.sectionType, this.sectionIndex)!;
  }

  get isFill() {
    return !(
      this.sectionType === SectionType.MAIN && this.part.type === PartType.PART
    );
  }

  get trigPos() {
    if (this.isFill) {
      return this.track.trigPos;
    } else {
      return 0;
    }
  }

  get tickCount() {
    if (this.isFill) {
      return this.track.tickCount;
    } else {
      return this.track.barCount * this.track.barLength;
    }
  }
}

class ChokeGroup {
  notes: Array<AudioBufferSourceNode> = [];

  choke(when: number) {
    // Sets the note to stop and then removes the note so the next choke doesn’t update this note’s stop time
    this.notes = this.notes.filter((note) => {
      note.stop(when);
      return false;
    });
  }

  add(note: AudioBufferSourceNode) {
    this.notes.push(note);
  }
}

export default class SongPlayerService extends Service {
  @service declare projectManager: ProjectManagerService;
  @service declare virtualPedal: VirtualPedalService;

  audioContext: AudioContext;
  drumsetVolume: GainNode;
  @tracked _isPlaying = false;
  @tracked loop = true;
  @tracked selectedTrack?: SelectedTrack;
  @tracked trackPosition = 0;
  songDestructor?: () => void;
  @tracked _currentSong?: Song;
  @tracked _currentDrumset?: Drumset;

  constructor(properties: object | undefined) {
    super(properties);
    this.audioContext = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: 44100,
    });
    this.drumsetVolume = new GainNode(this.audioContext, { gain: 1 });
    this.drumsetVolume.connect(this.audioContext.destination);
  }

  set song(song: Song) {
    this.songDestructor?.();
    this._currentSong = song;
    this.setDrumset(song.drumset);
    song.on('changeDrumset', (drumsetName) => {
      this.setDrumset(drumsetName);
    });
    this.selectedTrack = this.findTrack(song);
    this.audioContext.suspend();
    this._isPlaying = false;
    const addTrackDestructor = song.on(
      'addTrack',
      (
        part: SongPart,
        sectionType: SectionType,
        sectionIndex: number,
        /*trackIndex: number,*/
      ) => {
        this.selectTrack(part, sectionType, sectionIndex);
      },
    );
    const removeTrackDestructor = song.on(
      'removeTrack',
      (part: SongPart, sectionType: SectionType, sectionIndex: number) => {
        if (
          this.selectedTrack?.part === part &&
          this.selectedTrack?.sectionType === sectionType &&
          this.selectedTrack?.sectionIndex === sectionIndex
        ) {
          this.findTrack(song);
        }
      },
    );
    this.songDestructor = () => {
      addTrackDestructor();
      removeTrackDestructor();
    };
    this.reset();
  }

  get song() {
    return this._currentSong!;
  }

  setDrumset(name: string) {
    this.projectManager
      .findDrumset(name)
      .parse()
      .then((drumset) => {
        if (drumset) {
          drumset.prepareAudio(this.audioContext);
          this._currentDrumset = drumset;
          const drumsetGain = drumset.volume / 100;
          this.drumsetVolume.gain.setValueAtTime(
            drumsetGain,
            this.audioContext.currentTime,
          );
        }
      });
  }

  get drumset() {
    return this._currentDrumset;
  }

  get track() {
    return this.selectedTrack?.track;
  }

  get isPlaying() {
    return this._isPlaying;
  }

  @action
  stop(stopAudio: number = 0, stopAtTime: number = 0) {
    if (stopAudio === 0) {
      clearTimeout(this.loopTimer);
    }
    this.#stopAtTime = stopAtTime;
    this.playingEvents?.forEach((note) => note.stop(stopAudio));
    this.playingEvents = [];
  }

  @action
  findTrack(song: Song): SelectedTrack | undefined {
    let searchParts;
    // If the intro has more than four bars, it is likely a One Press Song
    if ((song.intro?.main?.barCount ?? 0) > 4) {
      searchParts = [song.intro, ...song.parts, song.outro];
    } else if ((song.outro?.main?.barCount ?? 0) > 4) {
      searchParts = [song.outro, ...song.parts, song.intro];
    } else {
      searchParts = [...song.parts, song.intro, song.outro];
    }
    this.selectedTrack = searchParts
      .flatMap((part) => {
        return [
          part.main && new SelectedTrack(part, SectionType.MAIN, 0),
          ...part.fills.map(
            (_fill, index) => new SelectedTrack(part, SectionType.FILL, index),
          ),
          part.transition && new SelectedTrack(part, SectionType.TRANSITION, 0),
        ];
      })
      .find(elementExists);
    this.setupSelectedTrack();
    return this.selectedTrack;
  }

  get bpm() {
    return this.song?.bpm ?? 120;
  }

  selectTrack(part: SongPart, sectionType: SectionType, index: number) {
    this.selectedTrack = new SelectedTrack(part, sectionType, index);
    this.setupSelectedTrack();
  }

  setupSelectedTrack() {
    if (this.selectedTrack) {
      this.loop =
        this.selectedTrack.sectionType === SectionType.MAIN &&
        this.selectedTrack.part.type === PartType.PART;
      this.trackPosition = this.selectedTrack.trigPos;
      this.stop();
      this.reset();
    }
  }

  ticksPerSecond(tickToQuarter: number) {
    return (tickToQuarter / 60) * this.bpm;
  }

  async playAccent(effect: EffectFile, volume = 100) {
    playAccent(this.audioContext, this.drumsetVolume, effect, volume);
  }

  async playInstrument(instrument: Instrument, velocityValue: number) {
    playInstrument(
      this.audioContext,
      this.drumsetVolume,
      instrument,
      0,
      0,
      velocityValue,
    );
  }

  #chokeGroups = new Array(15).fill(0).map(() => new ChokeGroup());
  getChokeGroup(group: number): ChokeGroup {
    if (group < 0 || group > 15) throw new Error('Invalid choke group');
    return this.#chokeGroups[group]!;
  }

  addToChokeGroup(group: number, note: AudioBufferSourceNode) {
    this.getChokeGroup(group).add(note);
  }

  playingEvents: AudioBufferSourceNode[] = [];
  loopTimer = 0;

  /*
   * Schedules MIDI events to be played
   * @param {number} timeOffset - The time to start playing the song. This is based on the AudioContext#currentTime. If 0 it will use AudioContext#currentTime
   * @param {number} playFromTick - The tick to start playing the song from
   * @returns {void}
   */
  scheduleEvents(
    timeOffset = 0,
    playFromTick = 0,
    playToTick = -1,
    eventStartIndex = -1,
  ) {
    // Bail if playback is not active
    if (!this.isPlaying) {
      return;
    }

    // Get the current audio time on which to base all calculations

    // Everything is based on the current track
    if (this.selectedTrack && this.track) {
      const track = this.track;
      const tickCount = this.selectedTrack.tickCount;
      const events = this.track.events;

      // How many ticks to we have per second
      const ticksPerSecond = this.ticksPerSecond(track.tickToQuarter);

      const time = this.audioContext.currentTime;

      // If we don’t have a timeOffset, set it to the current time
      if (timeOffset === 0) {
        timeOffset = time;
        this.playingEvents = new Array(this.track.events.length);
        // The start time is the current time minus the time it takes to
        //  play from the start to the playFromTick
        this.#startTime = time - playFromTick / ticksPerSecond;
      }

      // The time to start playing the next events
      const playStart = timeOffset;

      const playFrom = playFromTick;
      // We play to playToTick, or for two bars, or up to the end of the track
      //   whichever is soonest
      let playTo = Math.min(
        tickCount,
        playToTick === -1 ? playFromTick + track.barLength * 2 : playToTick,
      );
      // The duration is the time it takes to play from playFrom to playTo
      const duration = (playTo - playFrom) / ticksPerSecond;

      // If we’re playing to the end of the track, add the extra ticks (for a fill)
      if (playTo === tickCount) {
        playTo = playTo + track.extraTicks;
      }

      let eventEndIndex = events.length;
      for (
        let i = Math.max(eventStartIndex, 0), ii = events.length;
        i < ii;
        i++
      ) {
        if (eventStartIndex < 0 && events[i]!.tick >= playFromTick) {
          eventStartIndex = i;
        }
        if (events[i]!.tick >= playTo) {
          eventEndIndex = i;
          break;
        }
      }
      // If we have no notes to play, then we’re done
      if (eventStartIndex < 0) {
        eventStartIndex = eventEndIndex;
      }

      // Set a loop timer to play the next part of the song
      this.loopTimer = setTimeout(
        () => {
          // A flag to indicate if we should repeat from the beginning
          let repeat = false;

          // Increase playback position by two bars
          playFromTick = playFrom + track.barLength * 2;
          // If we’ve reached the end of the track, reset and repeat
          if (playFromTick >= tickCount) {
            playFromTick = 0;
            eventEndIndex = 0;
            repeat = true;
          }

          // If we’re about to repeat and we’re not looping, stop playback
          if (repeat && !this.loop) {
            this.stop(this.audioContext.currentTime + 4, time + duration);
          } else {
            // Schedule the next part of the song to be played
            this.scheduleEvents(
              playStart + duration,
              playFromTick,
              playToTick,
              eventEndIndex,
            );
          }
        },
        // Set the timeout to the time it takes to play the next part of the song, less 1s
        (playStart + duration - time) * 1000 - 1000,
      );

      let event, onWhen, offWhen, instrument, note;
      // Loop through the events to be played
      for (let i = eventStartIndex; i < eventEndIndex; i++) {
        event = events[i]!;
        // If the event is a note on event and it’s within the play range
        onWhen = playStart + (event.tick - playFromTick) / ticksPerSecond;
        offWhen = onWhen + event.length / ticksPerSecond;
        instrument = this.drumset?.instruments[event.note];
        if (instrument) {
          // Stop all instruments in the same choke group
          if (instrument.chokeGroup > 0) {
            const chokeGroup = this.getChokeGroup(instrument.chokeGroup);
            chokeGroup.choke(onWhen);
          }
          note = playInstrument(
            this.audioContext,
            this.drumsetVolume,
            instrument,
            onWhen,
            offWhen,
            event.velocity,
          );
          if (note) {
            // Add the note to the choke group
            if (instrument.chokeGroup > 0) {
              this.addToChokeGroup(instrument.chokeGroup, note);
            }
            this.playingEvents[i] = note;
          }
        }
      }
    }
  }

  playFrom(tick: number) {
    if (this._isPlaying) return;
    if (this.audioContext.state === 'suspended') {
      this.audioContext.resume();
    }
    this.virtualPedal.stop();
    this.#stopAtTime = undefined;
    this.ticks = Math.max(tick, this.selectedTrack?.trigPos ?? 0);
    this._isPlaying = true;
    this.scheduleEvents(0, tick);
    requestAnimationFrame(this.tick);
  }

  #startTime = 0;
  #stopAtTime?: number;
  @tracked ticks = 0;
  reset(repeat = false) {
    if (repeat && !this.loop && this._isPlaying) {
      this._isPlaying = false;
    }
    this.ticks = this.selectedTrack?.trigPos ?? 0;
  }

  @action
  async tick() {
    if (!this._isPlaying) {
      return;
    }
    if (this.selectedTrack && this.track) {
      const time = this.audioContext.currentTime - this.#startTime;
      const ticksOffset = this.selectedTrack.trigPos;
      const ticksPlaying = this.selectedTrack.tickCount;
      const totalTicksPlayed = Math.floor(
        time * this.ticksPerSecond(this.track.tickToQuarter),
      );
      const tickInCurrentLoop = totalTicksPlayed % ticksPlaying;
      this.ticks = ticksOffset + tickInCurrentLoop;
      if (
        this.#stopAtTime !== undefined &&
        this.#stopAtTime <= this.audioContext.currentTime
      ) {
        if (this.#stopAtTime > 0) {
          this.ticks = this.track.totalTicks;
        }
        this.#stopAtTime = undefined;
        this._isPlaying = false;
        return;
      }
    }
    requestAnimationFrame(this.tick);
  }
}

declare module '@ember/service' {
  interface Registry {
    'song-player': SongPlayerService;
  }
}
