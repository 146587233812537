import type { Instrument, Velocity } from 'editor/models/drumset';
import constrainValue from './constrain-value';
import buildGainTable from './build-gain-table';

const GAIN_TABLE = buildGainTable();

export default function playInstrument(
  audioContext: BaseAudioContext,
  masterGainNode: GainNode,
  instrument: Instrument,
  onWhen = 0,
  offWhen = 0,
  velocityValue = 127,
) {
  // Ensure we don’t have a negative value
  onWhen = onWhen < 0 ? 0 : onWhen;
  const [gainNode, velocity, volume] = getGainNodeForVelocity(
    audioContext,
    masterGainNode,
    instrument,
    velocityValue,
  )!;
  if (velocity && gainNode) {
    if (velocity.audio) {
      const source = audioContext.createBufferSource();
      source.connect(gainNode);
      source.buffer = velocity.audio;
      source.start(onWhen);
      if (offWhen > onWhen && instrument.nonPercussion) {
        gainNode.gain.cancelScheduledValues(onWhen);
        gainNode.gain.setValueAtTime(volume, offWhen);
        gainNode.gain.linearRampToValueAtTime(0.01, offWhen + 0.01);
        source.stop(offWhen + 0.015);
      }
      return source;
    } else {
      console.error('No audio for instrument');
    }
  }
}

export function getGainNodeForVelocity(
  audioContext: BaseAudioContext,
  masterGainNode: GainNode,
  instrument: Instrument,
  velocityValue: number,
): readonly [GainNode | undefined, Velocity | undefined, volume: number] {
  velocityValue = constrainValue(velocityValue, 0, 127);
  if (instrument.velocities.length === 0) {
    return [undefined, undefined, 0];
  }
  let highIndex = instrument.velocities.length - 1;
  while (instrument.velocities[highIndex]!.vel > velocityValue) {
    highIndex--;
  }

  const groupVelocity = instrument.velocities[highIndex]!.vel;
  let lowIndex = highIndex;
  while (instrument.velocities[lowIndex]!.vel == groupVelocity) {
    lowIndex--;
    if (lowIndex < 0) {
      break;
    }
  }
  lowIndex++;
  // get a random index between lowIndex and including highIndex
  const velocityIndex = Math.floor(
    Math.random() * (highIndex - lowIndex + 1) + lowIndex,
  );
  const velocity = instrument.velocities[velocityIndex];
  if (velocity) {
    let volume = 0;
    if (highIndex < instrument.velocities.length - 1) {
      volume =
        GAIN_TABLE[instrument.velocities[highIndex + 1]!.vel - 1]![
          velocityValue
        ] ?? 0;
    } else {
      volume = GAIN_TABLE[127]![velocityValue] ?? 0;
    }
    volume *= instrument.volume / 100;
    const gainNode = new GainNode(audioContext, { gain: volume });
    gainNode.connect(masterGainNode);
    return [gainNode, velocity, volume];
  }
  return [undefined, undefined, 0];
}
