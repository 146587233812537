import Controller from '@ember/controller';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  AddSongToPlaylistCommand,
  RemoveSongFromPlaylistCommand,
  SortPlaylistCommand,
} from 'editor/models/playlist-commands';
import type ProjectManagerService from 'editor/services/project-manager';
import type {
  Playlist,
  SongFile,
  SongReference,
} from 'editor/services/project-manager';
import type UndoManagerService from 'editor/services/undo-manager';

export default class RestrictedPlaylistsPlaylistController extends Controller {
  @service declare undoManager: UndoManagerService;
  @service declare projectManager: ProjectManagerService;

  declare model: Playlist;
  @tracked currentSongRef?: SongReference;
  @tracked currentSong?: SongFile;

  get playlist() {
    return this.model;
  }

  get folders() {
    return this.projectManager.songFolders;
  }

  @action
  async addSong(song: SongFile) {
    this.currentSongRef = await this.undoManager.executeCommand(
      new AddSongToPlaylistCommand(this.playlist, song),
    );
  }

  @action
  async dragSongToPlaylist(
    fromElement: HTMLElement,
    fromIndex: number,
    _toElement: HTMLElement,
    toIndex: number,
  ) {
    const folderFromIndex = parseInt(fromElement.dataset['folderIndex']!);
    const fromFolder = this.folders[folderFromIndex]!;
    const song = fromFolder.songs[fromIndex];
    if (song) {
      this.currentSongRef = await this.undoManager.executeCommand(
        new AddSongToPlaylistCommand(this.playlist, song, toIndex),
      );
    }
  }

  @action
  removeSong(song: SongReference) {
    this.undoManager.executeCommand(
      new RemoveSongFromPlaylistCommand(this.playlist, song),
    );
  }

  @action
  removeSelectedSong() {
    if (this.selectedSongRef) {
      this.removeSong(this.selectedSongRef);
    }
  }

  @action
  sortPlaylist(oldIndex: number, newIndex: number) {
    // eslint-disable-next-line ember/no-runloop
    next(() => {
      this.undoManager.executeCommand(
        new SortPlaylistCommand(this.playlist, oldIndex, newIndex),
      );
    });
  }

  @tracked selectedSongRef?: SongReference;
  @action
  selectSong(songRef?: SongReference) {
    this.selectedSongRef = songRef;
    this.projectManager.currentSong = songRef?.song;
  }
}
