import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isDevelopingApp, macroCondition } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import { RenameProjectCommand } from 'editor/models/project-commands';
import type ProjectManagerService from 'editor/services/project-manager';
import { SongFile, SongFolder } from 'editor/services/project-manager';
import type UndoManagerService from 'editor/services/undo-manager';
import nameFilter from 'editor/utils/name-filter';

function waitForSongs(folder: SongFolder): Promise<void> {
  return new Promise((resolve) => {
    const checkSongs = () => {
      if (folder.songs.length > 0) {
        resolve();
      } else {
        setTimeout(checkSongs, 100);
      }
    };
    checkSongs();
  });
}

export default class RestrictedController extends Controller {
  @service declare projectManager: ProjectManagerService;
  @service declare undoManager: UndoManagerService;
  @service declare router: RouterService;
  @tracked displayHelpPopup = false;

  nameFilter = nameFilter;

  get browserIsSupported() {
    const supportsDirectoryPicker = window.showDirectoryPicker !== undefined;
    const supportsNonDestructiveArrayMethods =
      Array.prototype.toSorted !== undefined;
    return supportsDirectoryPicker && supportsNonDestructiveArrayMethods;
  }

  @action
  async sync() {
    await this.projectManager.syncToSDCard();
  }

  @action
  async save() {
    await this.projectManager.save();
  }

  @action
  async toggleTheme() {
    await this.projectManager.toggleTheme(false);
  }

  @action
  async importSong() {
    const currentSong = this.projectManager.currentSong;
    const commands = await this.projectManager.importSong();
    if (commands) {
      commands.on('execute', async (results) => {
        const [song] = results;
        if (song instanceof SongFile) {
          this.transitionToSong(song);
        }
        if (song instanceof SongFolder) {
          const folder = song as SongFolder;
          await waitForSongs(folder);
          this.transitionToFolder(song as SongFolder);
        }
      });
      commands.on('reverse', () => {
        if (currentSong) {
          this.transitionToSong(currentSong);
        } else {
          this.router.transitionTo('restricted.songs');
        }
      });

      await this.undoManager.executeCommand(commands);
    }
  }

  transitionToSong(song: SongFile) {
    if (
      this.router.isActive(
        'restricted.songs.folder.song',
        song.folder.path,
        song.path,
      )
    ) {
      this.router.refresh('restricted.songs.folder.song');
    } else {
      this.router.transitionTo(
        'restricted.songs.folder.song',
        song.folder.path,
        song.path,
      );
    }
  }

  transitionToFolder(folder: SongFolder) {
    if (this.router.isActive('restricted.songs.folder', folder.path)) {
      this.router.refresh('restricted.songs.folder');
    } else {
      this.router.transitionTo('restricted.songs.folder', folder.path);
    }
  }

  @action
  async importDrumset() {
    const command = await this.projectManager.importDrumset();
    if (command) {
      command.on('execute', (drumset) => {
        this.router.transitionTo('restricted.drumsets.drumset', drumset.path);
      });
      command.on('reverse', () => {
        this.router.transitionTo('restricted.drumsets');
      });
      this.undoManager.executeCommand(command);
    }
  }

  @action
  async openProject() {
    let handle: FileSystemDirectoryHandle;
    try {
      handle = await window.showDirectoryPicker({
        id: 'open-project',
        startIn: 'documents',
        mode: 'readwrite',
      });
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }
    if (await this.projectManager.loadProject(handle)) {
      const folder =
        this.projectManager.currentFolder ?? this.projectManager.songFolders[0];
      const song = this.projectManager.currentSong ?? folder?.songs[0];
      if (folder && song) {
        this.router.transitionTo(
          'restricted.songs.folder.song',
          folder.path,
          song.path,
        );
      } else {
        this.router.transitionTo('restricted.songs');
      }
    } else {
      alert('Invalid project');
    }
  }

  @action
  async newProject() {
    let projectDirectoryHandle: FileSystemDirectoryHandle;
    try {
      projectDirectoryHandle = await window.showDirectoryPicker({
        id: 'new-project',
        startIn: 'documents',
      });

      const entries: FileSystemHandle[] = [];
      for await (const [, entry] of projectDirectoryHandle.entries()) {
        entries.push(entry);
      }

      if (entries.length > 0) {
        alert('Please select an empty directory to create a new project in');
        return;
      }

      const projectFileHandle = await window.showSaveFilePicker({
        startIn: projectDirectoryHandle,
        suggestedName: 'New Project.bbp',
        types: [
          {
            description: 'Beatbuddy Project',
            accept: {
              'application/x-beatbuddy-project': ['.bbp'],
            },
          },
        ],
      });

      if (!(await projectDirectoryHandle.resolve(projectFileHandle))) {
        alert('Please create a project file within the project directory');
        return;
      }
      await this.projectManager.createProject(
        projectDirectoryHandle,
        projectFileHandle,
      );
      this.router.transitionTo('restricted.songs');
    } catch (err: any) {
      if (err.name === 'AbortError' && /user aborted/i.test(err.message)) {
        return;
      }
      throw err;
    }
  }

  @action
  closeProject() {
    if (this.warnIfDirty()) {
      this.projectManager.closeProject();
      this.router.transitionTo('restricted.index');
    }
  }

  @action
  warnIfDirty(ev?: BeforeUnloadEvent): boolean {
    if (macroCondition(isDevelopingApp())) {
      // During development, I don’t want to be prompted to save changes when I refresh the page
      return true;
    }
    if (this.projectManager.isDirty) {
      const confirmed = confirm(
        'You have not saved your changes, are you sure you want to leave this page?\nClick "Cancel" to remain on this page.',
      );
      if (!confirmed) {
        if (ev) {
          ev.preventDefault();
          ev.returnValue = '';
        }
        return false;
      }
    }
    return true;
  }

  @action
  toggleHelpPopup(force: boolean) {
    this.displayHelpPopup = force;
  }

  @action
  updateProjectName(name: string) {
    if (this.projectManager.projectFile) {
      this.undoManager.executeCommand(
        new RenameProjectCommand(this.projectManager, name),
      );
    }
  }
}
