import { template } from "@ember/template-compiler";
import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import and from 'editor/helpers/and';
import eq from 'editor/helpers/eq';
import gte from 'editor/helpers/gte';
import htmlId from 'editor/helpers/html-id';
import pick from 'editor/helpers/pick';
import queue from 'editor/helpers/queue';
import { CompositeCommand } from 'editor/models/command';
import type Drumset from 'editor/models/drumset';
import { Instrument, type IInstrument } from 'editor/models/drumset';
import { AddInstrumentToDrumsetCommand, AddNewInstrumentToDrumsetCommand, DeleteInstrumentCommand, RenameInstrumentCommand, UpdateDrumsetVolumeCommand, UpdateInstrumentChokeGroupCommand, UpdateInstrumentFillChokeDelayCommand, UpdateInstrumentFillChokeGroupCommand, UpdateInstrumentIdCommand, UpdateInstrumentNonPercussionCommand, UpdateInstrumentPolyphonyCommand, UpdateInstrumentVolumeCommand } from 'editor/models/drumset-commands';
import onClickOutside from 'editor/modifiers/on-click-outside';
import onKey from 'editor/modifiers/on-key';
import selected from 'editor/modifiers/selected';
import type ProjectManagerService from 'editor/services/project-manager';
import type SongPlayerService from 'editor/services/song-player';
import type UndoManagerService from 'editor/services/undo-manager';
import gmDrumMap from 'editor/utils/gm-drum-map';
import nameFilter from 'editor/utils/name-filter';
import velcro from 'ember-velcro/modifiers/velcro';
import Button from './button';
import EditableField from './editable-field';
import InstrumentImpport from './instrument-import';
import InstrumentSettingsEditor from './instrument-settings-editor';
import MenuButton from './menu-button';
import OptionButton from './option-button';
import SampleEditor from './sample-editor';
import SelectableItem from './selectable-item';
const UNDO_DELAY = 800;
interface DrumsetEditorSignature {
    Element: HTMLDivElement;
    Args: {
        drumset: Drumset;
        // eslint-disable-next-line no-unused-vars
        renameDrumset: (name: string) => void;
    };
    Blocks: {
    };
}
export class MultiInstrument implements IInstrument {
    instruments: IInstrument[];
    id = -1;
    name = 'Multiple Instruments';
    constructor(instruments1: IInstrument[]){
        this.instruments = instruments1;
    }
    markDirty() {
        this.instruments.forEach((i1)=>i1.markDirty());
    }
    // if all instruments share the same chokeGroup, return that value, else return -1
    get chokeGroup() {
        const chokeGroups1 = this.instruments.map((i1)=>i1.chokeGroup);
        return chokeGroups1.every((cg1)=>cg1 === chokeGroups1[0]) ? chokeGroups1[0]! : -1;
    }
    set chokeGroup(value1: number) {
        this.instruments.forEach((i1)=>(i1.chokeGroup = value1));
    }
    get poly() {
        const polys1 = this.instruments.map((i1)=>i1.poly);
        return polys1.every((p1)=>p1 === polys1[0]) ? polys1[0]! : -1;
    }
    set poly(value1: number) {
        this.instruments.forEach((i1)=>(i1.poly = value1));
    }
    get volume() {
        const volumes1 = this.instruments.map((i1)=>i1.volume);
        return volumes1.every((v1)=>v1 === volumes1[0]) ? volumes1[0]! : -1;
    }
    get volumeDb() {
        if (this.volume === -1) return -1;
        return Number((20 * Math.log10(this.volume / 100)).toFixed(1));
    }
    set volume(value1: number) {
        this.instruments.forEach((i1)=>(i1.volume = value1));
    }
    get fillChokeGroup() {
        const fillChokeGroups1 = this.instruments.map((i1)=>i1.fillChokeGroup);
        return fillChokeGroups1.every((fcg1)=>fcg1 === fillChokeGroups1[0]) ? fillChokeGroups1[0]! : -1;
    }
    set fillChokeGroup(value1: number) {
        this.instruments.forEach((i1)=>(i1.fillChokeGroup = value1));
    }
    get fillChokeDelay() {
        const fillChokeDelays1 = this.instruments.map((i1)=>i1.fillChokeDelay);
        return fillChokeDelays1.every((fcd1)=>fcd1 === fillChokeDelays1[0]) ? fillChokeDelays1[0]! : -1;
    }
    set fillChokeDelay(value1: number) {
        this.instruments.forEach((i1)=>(i1.fillChokeDelay = value1));
    }
    get nonPercussion() {
        const nonPercussions1 = this.instruments.map((i1)=>i1.nonPercussion);
        return nonPercussions1.every((np1)=>np1 === nonPercussions1[0]) ? nonPercussions1[0]! == 1 ? 1 : 0 : -1;
    }
    set nonPercussion(value1: number) {
        this.instruments.forEach((i1)=>(i1.nonPercussion = value1 ? 1 : 0));
    }
}
function allInstruments(instrument1: IInstrument): IInstrument[] {
    if (instrument1 instanceof MultiInstrument) {
        return instrument1.instruments.map((i1)=>i1);
    } else {
        return [
            instrument1
        ];
    }
}
export default class DrumsetEditorComponent extends Component<DrumsetEditorSignature> {
    @service
    projectManager: ProjectManagerService;
    @service
    songPlayer: SongPlayerService;
    @service
    undoManager: UndoManagerService;
    @service
    router: RouterService;
    @tracked
    showInstrumentList = false;
    @tracked
    showImportModal = false;
    @tracked
    menuInstrument?: IInstrument;
    @tracked
    selectedInstruments: IInstrument[] = [];
    undoUpdateTimer: number = 0;
    constructor(owner1: unknown, args1: DrumsetEditorSignature['Args']){
        super(owner1, args1);
        this.router.on('routeDidChange', ()=>{
            if (this.drumset.playableInstruments.length === 0) this.selectedInstruments = [];
            else this.selectedInstruments = [
                this.drumset.playableInstruments[0]!
            ];
        });
        if (this.drumset.playableInstruments.length === 0) this.selectedInstruments = [];
        else this.selectedInstruments = [
            this.drumset.playableInstruments[0]!
        ];
    }
    get drumset() {
        return this.args.drumset!;
    }
    get instruments(): IInstrument[] {
        return this.drumset.playableInstruments;
    }
    get availableInstruments() {
        return Array.from(gmDrumMap.values()).filter(({ id: id1 })=>!this.instruments.some((i1)=>i1.id === id1));
    }
    @action
    isSelectedInstrument(instrument1: IInstrument) {
        return this.selectedInstruments.includes(instrument1);
    }
    get instrument(): IInstrument {
        if (this.selectedInstruments.length === 1) {
            return this.selectedInstruments[0]!;
        } else {
            return new MultiInstrument(this.selectedInstruments);
        }
    }
    @action
    selectInstrument(instrument1: IInstrument | undefined, ev1?: KeyboardEvent | MouseEvent) {
        ev1?.stopPropagation();
        if (instrument1 === undefined) {
            this.selectedInstruments = [];
            return;
        }
        if (ev1?.ctrlKey || ev1?.metaKey) {
            if (this.isSelectedInstrument(instrument1)) {
                this.selectedInstruments = this.selectedInstruments.filter((i1)=>i1 !== instrument1);
            } else {
                this.selectedInstruments = [
                    ...this.selectedInstruments,
                    instrument1
                ];
            }
        } else if (ev1?.shiftKey) {
            const firstSelectedInstrument1 = this.selectedInstruments.at(0);
            const lastSelectedInstrument1 = this.selectedInstruments.at(-1);
            if (firstSelectedInstrument1 && lastSelectedInstrument1) {
                const firstIndex1 = this.instruments.indexOf(firstSelectedInstrument1);
                const lastIndex1 = this.instruments.indexOf(lastSelectedInstrument1);
                const currentIndex1 = this.instruments.indexOf(instrument1);
                if (currentIndex1 < firstIndex1) {
                    this.selectedInstruments = [
                        ...this.selectedInstruments,
                        ...this.instruments.slice(currentIndex1, firstIndex1 + 1)
                    ];
                } else if (currentIndex1 > lastIndex1) {
                    this.selectedInstruments = [
                        ...this.selectedInstruments,
                        ...this.instruments.slice(lastIndex1, currentIndex1 + 1)
                    ];
                } else {
                    this.selectedInstruments = [
                        ...this.selectedInstruments,
                        ...this.instruments.slice(firstIndex1, currentIndex1 + 1)
                    ];
                }
            } else {
                this.selectedInstruments = [
                    instrument1
                ];
            }
        } else {
            this.selectedInstruments = [
                instrument1
            ];
        }
        // remove window selection
        window.getSelection()?.removeAllRanges();
    }
    @action
    selectNextInstrument(ev1: KeyboardEvent) {
        ev1.preventDefault();
        const firstSelectedInstrument1 = this.selectedInstruments.at(0);
        const lastSelectedInstrument1 = this.selectedInstruments.at(-1);
        if (firstSelectedInstrument1 && lastSelectedInstrument1) {
            const firstIndex1 = this.instruments.indexOf(firstSelectedInstrument1);
            const lastIndex1 = this.instruments.indexOf(lastSelectedInstrument1);
            if (ev1.shiftKey && firstIndex1 <= lastIndex1) {
                let nextIndex1 = lastIndex1 + 1;
                if (nextIndex1 >= this.instruments.length) {
                    nextIndex1 = lastIndex1;
                }
                this.selectedInstruments = this.instruments.slice(firstIndex1, nextIndex1 + 1);
            } else if (ev1.shiftKey && firstIndex1 >= lastIndex1) {
                this.selectedInstruments = this.selectedInstruments.slice(0, -1);
            } else if (!ev1.shiftKey) {
                let nextIndex1 = lastIndex1 + 1;
                if (nextIndex1 >= this.instruments.length) {
                    nextIndex1 = 0;
                }
                this.selectedInstruments = this.instruments.slice(nextIndex1, nextIndex1 + 1);
            }
        } else {
            this.selectedInstruments = this.instruments.slice(0, 1);
        }
        document.querySelector('.instrument.selected')?.scrollIntoView({
            block: 'start'
        });
    }
    @action
    selectPreviousInstrument(ev1: KeyboardEvent) {
        ev1.preventDefault();
        const firstSelectedInstrument1 = this.selectedInstruments.at(0);
        const lastSelectedInstrument1 = this.selectedInstruments.at(-1);
        if (firstSelectedInstrument1 && lastSelectedInstrument1) {
            const firstIndex1 = this.instruments.indexOf(firstSelectedInstrument1);
            const lastIndex1 = this.instruments.indexOf(lastSelectedInstrument1);
            if (ev1.shiftKey && lastIndex1 <= firstIndex1) {
                let nextIndex1 = lastIndex1 - 1;
                if (nextIndex1 < 0) {
                    nextIndex1 = 0;
                }
                this.selectedInstruments = [
                    ...this.selectedInstruments,
                    this.instruments.at(nextIndex1)!
                ];
            } else if (ev1.shiftKey && lastIndex1 >= firstIndex1) {
                this.selectedInstruments = this.selectedInstruments.slice(0, -1);
            } else if (!ev1.shiftKey) {
                let nextIndex1 = lastIndex1 - 1;
                if (nextIndex1 < 0) {
                    nextIndex1 = this.instruments.length - 1;
                }
                this.selectedInstruments = this.instruments.slice(nextIndex1, nextIndex1 + 1);
            }
        } else {
            this.selectedInstruments = this.instruments.slice(-1);
        }
        document.querySelector('.instrument.selected')?.scrollIntoView({
            block: 'end'
        });
    }
    @action
    deleteSelectedInstruments() {
        const commands1 = this.selectedInstruments.map((i1)=>{
            return new DeleteInstrumentCommand(this.drumset, <Instrument>i1);
        });
        const command1 = new CompositeCommand(commands1[0]!.name, commands1);
        command1.on('execute', ()=>{
            this.selectedInstruments = this.instruments.slice(0, 1);
        });
        command1.on('reverse', ()=>{
            this.selectedInstruments = commands1.map((c1)=>c1.instrument);
        });
        this.undoManager.executeCommand(command1);
    }
    @action
    showMenuFor(instrument1: IInstrument | undefined, ev1?: MouseEvent) {
        if (this.menuInstrument != instrument1) {
            ev1?.preventDefault();
            ev1?.stopPropagation();
            this.menuInstrument = instrument1;
        }
    }
    @action
    shouldShowMenuFor(instrument1: IInstrument) {
        return this.menuInstrument === instrument1;
    }
    @action
    toggleShowInstrumentList(force1: boolean | Event) {
        if (typeof force1 === 'boolean') {
            this.showInstrumentList = force1;
            return;
        }
        this.showInstrumentList = !this.showInstrumentList;
    }
    @action
    toggleShowImportModal(force1: boolean | Event) {
        if (typeof force1 === 'boolean') {
            this.showImportModal = force1;
            return;
        }
        this.showImportModal = !this.showImportModal;
    }
    @action
    addInstrument(inst1: {
        id: number;
        name: string;
    } | Instrument | undefined) {
        const command1 = new AddNewInstrumentToDrumsetCommand(this.drumset, inst1?.name ?? 'Unknown', inst1?.id);
        command1.on('execute', (instrument1)=>{
            this.selectInstrument(instrument1);
        });
        command1.on('reverse', ()=>{
            this.selectInstrument(undefined);
        });
        this.undoManager.executeCommand(command1);
    }
    @action
    addInstruments(instruments1: Instrument[]) {
        const commands1 = instruments1.map((i1)=>new AddInstrumentToDrumsetCommand(this.drumset, i1.copy()));
        const command1 = new CompositeCommand(commands1[0]!.name, commands1);
        command1.on('execute', (instruments1: Instrument[])=>{
            this.drumset.prepareAudio(this.songPlayer.audioContext);
            this.selectInstrument(instruments1.at(0));
        });
        command1.on('reverse', ()=>{
            this.selectInstrument(undefined);
        });
        this.undoManager.executeCommand(command1);
    }
    @action
    renameInstrument(instrument1: IInstrument, name1: string) {
        if (instrument1 instanceof Instrument) {
            this.undoManager.executeCommand(new RenameInstrumentCommand(instrument1, name1));
        }
    }
    @action
    deleteInstrument(instrument1: IInstrument) {
        const command1 = new DeleteInstrumentCommand(this.drumset, <Instrument>instrument1);
        command1.on('execute', ()=>{
            this.selectInstrument(undefined);
        });
        command1.on('reverse', ()=>{
            this.selectInstrument(instrument1);
        });
        this.undoManager.executeCommand(command1);
    }
    @action
    popupMiddleware() {
        return [
            // Calculates the maximum height for the accent popup list
            {
                name: 'Max height',
                options: {},
                async fn (state1: any) {
                    const availableHeight1 = document.documentElement.clientHeight;
                    const top1 = state1.rects.reference.y + state1.rects.reference.height;
                    const height1 = availableHeight1 - top1 - 20;
                    state1.elements.floating.style.maxHeight = `${height1}px`;
                    return state1;
                }
            }
        ];
    }
    get availableMidiIds() {
        return this.drumset.availableInstruments.map((i1)=>i1.id).map((id1)=>{
            const gmInstrument1 = gmDrumMap.get(id1);
            if (gmInstrument1) {
                return {
                    id: gmInstrument1.id,
                    name: gmInstrument1.name
                };
            } else {
                return {
                    id: id1,
                    name: ''
                };
            }
        });
    }
    updateDrumsetVolumeFrom?: number = undefined;
    @action
    updateDrumsetVolume(value1: string) {
        const volume1 = parseInt(value1);
        this.updateDrumsetVolumeFrom = this.updateDrumsetVolumeFrom ?? this.drumset.volume;
        clearTimeout(this.undoUpdateTimer);
        this.drumset.volume = volume1;
        this.undoUpdateTimer = window.setTimeout(()=>{
            if (volume1 !== this.updateDrumsetVolumeFrom) {
                this.undoManager.executeCommand(new UpdateDrumsetVolumeCommand(this.drumset, volume1, this.updateDrumsetVolumeFrom));
            }
            this.updateDrumsetVolumeFrom = undefined;
        }, UNDO_DELAY);
    }
    @action
    updateInstrumentID(value1: string) {
        const id1 = parseInt(value1);
        if (id1 !== this.instrument.id) {
            const command1 = new UpdateInstrumentIdCommand(this.drumset, this.instrument, id1);
            command1.on('execute', (instrument1)=>{
                this.selectInstrument(instrument1);
            });
            command1.on('reverse', (instrument1)=>{
                this.selectInstrument(instrument1);
            });
            this.undoManager.executeCommand(command1);
        }
    }
    updateVolumeCommands?: UpdateInstrumentVolumeCommand[];
    @action
    updateVolume(value1: string) {
        const volume1 = parseInt(value1);
        this.updateVolumeCommands = this.updateVolumeCommands ?? allInstruments(this.instrument).map((instrument1)=>new UpdateInstrumentVolumeCommand(instrument1, volume1));
        clearTimeout(this.undoUpdateTimer);
        this.instrument.volume = volume1;
        this.updateVolumeCommands.forEach((c1)=>(c1.toVolume = volume1));
        this.undoUpdateTimer = window.setTimeout(()=>{
            const commands1 = this.updateVolumeCommands!;
            this.undoManager.executeCommand(new CompositeCommand(commands1[0]!.name, commands1));
            this.updateVolumeCommands = undefined;
        }, UNDO_DELAY);
    }
    updatePolyphonyCommands?: UpdateInstrumentPolyphonyCommand[];
    @action
    updatePolyphony(value1: string) {
        const poly1 = parseInt(value1);
        this.updatePolyphonyCommands = this.updatePolyphonyCommands ?? allInstruments(this.instrument).map((instrument1)=>new UpdateInstrumentPolyphonyCommand(instrument1, poly1));
        clearTimeout(this.undoUpdateTimer);
        this.instrument.poly = poly1;
        this.updatePolyphonyCommands.forEach((c1)=>(c1.toPoly = poly1));
        this.undoUpdateTimer = window.setTimeout(()=>{
            const commands1 = this.updatePolyphonyCommands!;
            this.undoManager.executeCommand(new CompositeCommand(commands1[0]!.name, commands1));
            this.updatePolyphonyCommands = undefined;
        }, UNDO_DELAY);
    }
    updateChokeGroupCommands?: UpdateInstrumentChokeGroupCommand[];
    @action
    updateChokeGroup(value1: string) {
        const chokeGroup1 = parseInt(value1);
        this.updateChokeGroupCommands = this.updateChokeGroupCommands ?? allInstruments(this.instrument).map((instrument1)=>new UpdateInstrumentChokeGroupCommand(instrument1, chokeGroup1));
        clearTimeout(this.undoUpdateTimer);
        this.instrument.chokeGroup = chokeGroup1;
        this.updateChokeGroupCommands.forEach((c1)=>(c1.toChokeGroup = chokeGroup1));
        this.undoUpdateTimer = window.setTimeout(()=>{
            const commands1 = this.updateChokeGroupCommands!;
            this.undoManager.executeCommand(new CompositeCommand(commands1[0]!.name, commands1));
            this.updateChokeGroupCommands = undefined;
        }, UNDO_DELAY);
    }
    updateFillChokeGroupCommands?: UpdateInstrumentFillChokeGroupCommand[];
    @action
    updateFillChokeGroup(value1: string) {
        const fillChokeGroup1 = parseInt(value1);
        this.updateFillChokeGroupCommands = this.updateFillChokeGroupCommands ?? allInstruments(this.instrument).map((instrument1)=>new UpdateInstrumentFillChokeGroupCommand(instrument1, fillChokeGroup1));
        clearTimeout(this.undoUpdateTimer);
        this.instrument.fillChokeGroup = fillChokeGroup1;
        this.updateFillChokeGroupCommands.forEach((c1)=>(c1.toFillChokeGroup = fillChokeGroup1));
        this.undoUpdateTimer = window.setTimeout(()=>{
            const commands1 = this.updateFillChokeGroupCommands!;
            this.undoManager.executeCommand(new CompositeCommand(commands1[0]!.name, commands1));
            this.updateFillChokeGroupCommands = undefined;
        }, UNDO_DELAY);
    }
    @action
    updateFillChokeDelay(value1: string) {
        const fillChokeDelay1 = parseInt(value1);
        if (fillChokeDelay1 !== this.instrument.fillChokeDelay) {
            const commands1 = allInstruments(this.instrument).map((instrument1)=>new UpdateInstrumentFillChokeDelayCommand(instrument1, fillChokeDelay1));
            this.undoManager.executeCommand(new CompositeCommand(commands1[0]!.name, commands1));
        }
    }
    @action
    updateNonPercussion(value1: number) {
        if (value1 !== this.instrument.nonPercussion) {
            const commands1 = allInstruments(this.instrument).map((instrument1)=>new UpdateInstrumentNonPercussionCommand(instrument1, value1));
            this.undoManager.executeCommand(new CompositeCommand(commands1[0]!.name, commands1));
        }
    }
    get typedInstrument(): Instrument {
        return this.instrument as Instrument;
    }
    static{
        template(`
    <div class="flex flex-col h-full gap-2 dark:text-white">
      <header class="flex items-start justify-between gap-4 p-2 border-b shrink-0 dark:border-primary-darkBorderColor">
        <EditableField
          @model={{@drumset}}
          @field="name"
          @onChange={{@renameDrumset}}
          class="text-2xl font-bold"
        >{{@drumset.name}}</EditableField>
        <label class="flex items-center gap-1 text-nowrap shrink-0">
          <div class="w-52">Volume ({{@drumset.volumeDb}}dB)</div>
          <input
            type="range"
            min="1"
            max="159"
            value={{@drumset.volume}}
            class="w-full accent-primary"
            {{on "input" (pick "target.value" this.updateDrumsetVolume)}}
            {{on "dblclick" (fn this.updateDrumsetVolume "100")}}
          />
        </label>
      </header>
      <div class="flex items-start h-full overflow-auto">
        <div
          data-test-instrument-list
          class="flex flex-col h-full overflow-auto w-60 grow-0 shrink-0"
        >
          <nav aria-label="instrumnets menu" class="flex gap-1 px-2">
            <Button
              @icon="plus"
              id="add-instrument"
              {{on "click" this.toggleShowInstrumentList}}
            >Add Instrument</Button>
            {{#if this.showInstrumentList}}
              <div
                class="absolute z-50 flex flex-col overflow-auto bg-white shadow-md h-96"
                {{velcro "#add-instrument" middleware=(this.popupMiddleware)}}
                {{onClickOutside (fn this.toggleShowInstrumentList false)}}
              >
                <MenuButton
                  {{on
                    "click"
                    (queue
                      (fn this.addInstrument undefined)
                      (fn this.toggleShowInstrumentList false)
                    )
                  }}
                >Other Instrument</MenuButton>
                {{#each this.availableInstruments as |instrument|}}
                  <MenuButton
                    {{on
                      "click"
                      (queue
                        (fn this.addInstrument instrument)
                        (fn this.toggleShowInstrumentList false)
                      )
                    }}
                  >{{instrument.id}}. {{instrument.name}}</MenuButton>
                {{/each}}
              </div>
            {{/if}}
            <Button
              @icon="arrow-down-to-square"
              id="import-instruments"
              {{on "click" this.toggleShowImportModal}}
            >Import</Button>
            {{#if this.showImportModal}}
              <InstrumentImpport
                @drumset={{this.drumset}}
                @addInstruments={{queue
                  this.addInstruments
                  (fn this.toggleShowImportModal false)
                }}
                class="absolute z-50 flex flex-col min-h-0 p-2 overflow-auto bg-white rounded shadow-md"
                {{velcro
                  "#import-instruments"
                  placement="bottom-start"
                  middleware=(this.popupMiddleware)
                }}
                {{onClickOutside (fn this.toggleShowImportModal false)}}
              />
            {{/if}}
          </nav>
          {{! template-lint-disable no-invalid-interactive }}
          <div
            class="flex flex-col w-full h-full gap-px px-2 py-1 overflow-auto"
            {{onKey "ArrowDown" this.selectNextInstrument}}
            {{onKey "ArrowDown" this.selectNextInstrument shiftKey=true}}
            {{onKey "ArrowUp" this.selectPreviousInstrument}}
            {{onKey "ArrowUp" this.selectPreviousInstrument shiftKey=true}}
            {{onKey "Delete" this.deleteSelectedInstruments}}
            {{onKey "Backspace" this.deleteSelectedInstruments}}
            {{onKey "Escape" (fn this.selectInstrument undefined)}}
            {{on "click" (fn this.selectInstrument undefined)}}
          >
            {{#each this.instruments as |instrument|}}
              <div>
                {{! template-lint-disable no-invalid-interactive }}
                <div
                  class="flex items-center w-full gap-1 overflow-hidden rounded instrument text-nowrap playlist text-ellipsis whitespace-nowrap selected:bg-primary selected:text-white group/options dark:selected:bg-primary-darkButtonSelected dark:selected:text-black"
                  title={{instrument.name}}
                  {{on "click" (fn this.selectInstrument instrument)}}
                  {{selected when=(this.isSelectedInstrument instrument)}}
                >
                  <SelectableItem
                    @model={{instrument}}
                    @field="name"
                    @filter={{nameFilter}}
                    @when={{and
                      (this.isSelectedInstrument instrument)
                      (eq 1 this.selectedInstruments.length)
                    }}
                    @onChange={{fn this.renameInstrument instrument}}
                    title={{instrument.name}}
                    class="instrument-name"
                    {{selected when=(this.isSelectedInstrument instrument)}}
                  >{{instrument.id}}. {{instrument.name}}</SelectableItem>
                  <div
                    class="relative invisible text-black group-hover/options:visible selected:visible"
                    {{selected when=(this.isSelectedInstrument instrument)}}
                  >
                    <OptionButton
                      id={{concat "instrument-menu-" instrument.id}}
                      class="text-white group-hover/options:text-black selected:group-hover/options:text-white dark:group-hover/options:text-white dark:selected:group-hover/options:text-black dark:text-black"
                      {{selected when=(this.isSelectedInstrument instrument)}}
                      {{on "click" (fn this.showMenuFor instrument)}}
                    />
                    {{#if (this.shouldShowMenuFor instrument)}}
                      <nav
                        aria-label="Instrument options"
                        class="fixed z-50 flex flex-col bg-white border rounded shadow-lg dark:border-primary-darkBorderColor"
                        {{velcro
                          (htmlId (concat "instrument-menu-" instrument.id))
                          placement="left-start"
                        }}
                        {{onClickOutside (fn this.showMenuFor undefined)}}
                      >
                        <MenuButton
                          @icon="trash-can"
                          title="Delete instrument"
                          {{on
                            "click"
                            (queue
                              (fn this.deleteInstrument instrument)
                              (fn this.showMenuFor undefined)
                            )
                          }}
                        >Delete</MenuButton>
                      </nav>
                    {{/if}}
                  </div>
                </div>
              </div>
            {{/each}}
          </div>
        </div>
        <div
          data-test-instrument-panel
          class="flex flex-col h-full overflow-auto border-l grow dark:border-primary-darkBorderColor"
        >
          {{#if (gte this.selectedInstruments.length 1)}}
            {{#if (eq 1 this.selectedInstruments.length)}}
              <SampleEditor
                @drumset={{this.drumset}}
                @instrument={{this.typedInstrument}}
                class="border-t dark:border-primary-darkBorderColor"
                as |addSamples autoSort|
              >
                <InstrumentSettingsEditor
                  @instrument={{this.instrument}}
                  @renameInstrument={{fn this.renameInstrument this.instrument}}
                  @addSamples={{addSamples}}
                  @autoSort={{autoSort}}
                  @availableMidiIds={{this.availableMidiIds}}
                  @updateInstrumentID={{this.updateInstrumentID}}
                  @updateChokeGroup={{this.updateChokeGroup}}
                  @updateFillChokeGroup={{this.updateFillChokeGroup}}
                  @updateFillChokeDelay={{this.updateFillChokeDelay}}
                  @updateNonPercussion={{this.updateNonPercussion}}
                  @updatePolyphony={{this.updatePolyphony}}
                  @updateVolume={{this.updateVolume}}
                />
              </SampleEditor>
            {{else}}
              <InstrumentSettingsEditor
                @renameInstrument={{fn this.renameInstrument this.instrument}}
                @instrument={{this.instrument}}
                @availableMidiIds={{this.availableMidiIds}}
                @updateInstrumentID={{this.updateInstrumentID}}
                @updateChokeGroup={{this.updateChokeGroup}}
                @updateFillChokeGroup={{this.updateFillChokeGroup}}
                @updateFillChokeDelay={{this.updateFillChokeDelay}}
                @updateNonPercussion={{this.updateNonPercussion}}
                @updatePolyphony={{this.updatePolyphony}}
                @updateVolume={{this.updateVolume}}
              />
            {{/if}}
          {{/if}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        DrumsetEditor: typeof DrumsetEditorComponent;
    }
}
