import { template } from "@ember/template-compiler";
import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import add from 'editor/helpers/add';
import eq from 'editor/helpers/eq';
import htmlId from 'editor/helpers/html-id';
import notEq from 'editor/helpers/not-eq';
import or from 'editor/helpers/or';
import queue from 'editor/helpers/queue';
import { DeleteSongFolderCommand, MoveSongCommand, RenameFolderCommand, SortSongsCommand } from 'editor/models/folder-commands';
import { AddPlaylistCommand, AddSongToPlaylistCommand } from 'editor/models/playlist-commands';
import { DeleteSongCommand, DuplicateSongCommand, RenameSongCommand } from 'editor/models/song-commands';
import onClickOutside from 'editor/modifiers/on-click-outside';
import scrollIntoView from 'editor/modifiers/scroll-into-view';
import selected from 'editor/modifiers/selected';
import sortable from 'editor/modifiers/sortable';
import ProjectManagerService, { Playlist, SongFile, SongFolder } from 'editor/services/project-manager';
import UndoManagerService from 'editor/services/undo-manager';
import nameFilter from 'editor/utils/name-filter';
import { modifier } from 'ember-modifier';
import velcro from 'ember-velcro/modifiers/velcro';
import Button from './button';
import MenuButton from './menu-button';
import OptionButton from './option-button';
import SelectableItem from './selectable-item';
interface FolderSignature {
    Element: HTMLDivElement;
    Args: {
        folder: SongFolder;
        isSelected: boolean;
        selectedSong?: SongFile;
        folderIndex: number;
        filter: string;
        addSong: () => void;
        importSong: () => void;
        onMoveSong: (// eslint-disable-next-line no-unused-vars
        fromElement: HTMLElement, // eslint-disable-next-line no-unused-vars
        fromIndex: number, // eslint-disable-next-line no-unused-vars
        toElement: HTMLElement, // eslint-disable-next-line no-unused-vars
        toIndex: number) => void;
        // eslint-disable-next-line no-unused-vars
        onSelectSong: (folder: SongFolder, song: SongFile) => void;
    };
    Blocks: {
    };
}
const ensureInView = modifier((element1: HTMLElement)=>{
    var intersectionObserver1 = new IntersectionObserver((entries1)=>{
        entries1.forEach((entry1)=>{
            if (entry1.target == element1 && entry1.isIntersecting) {
                const rect1 = element1.getBoundingClientRect();
                if (rect1.height + rect1.top > window.innerHeight) {
                    element1.style.top = `-${rect1.top - 20}px`;
                    element1.style.maxHeight = `${window.innerHeight - 40}px`;
                }
            }
        });
    });
    intersectionObserver1.observe(element1);
});
export default class FolderComponent extends Component<FolderSignature> {
    @service
    projectManager: ProjectManagerService;
    @service
    undoManager: UndoManagerService;
    @service
    router: RouterService;
    @tracked
    _isOpen?: boolean;
    @tracked
    menuSong?: SongFile;
    @tracked
    menuFolder?: SongFolder;
    constructor(owner1: unknown, args1: FolderSignature['Args']){
        super(owner1, args1);
        this._isOpen = args1.isSelected;
        this.projectManager.on('folderChange', (folder1)=>{
            if (folder1 === this.folder) {
                this._isOpen = true;
            }
        });
    }
    get isOpen(): boolean {
        if (this.args.filter !== '' && this.matchesFilter) {
            return true;
        }
        return !!(this._isOpen ?? (this.folder === this.projectManager.currentFolder && this.projectManager.currentSong && this.folder.songs.includes(this.projectManager.currentSong)));
    }
    get matchesFilter() {
        return (this.args.filter === '' || this.folderMatchesFilter || this.filteredSongs.length > 0);
    }
    get folderMatchesFilter() {
        return this.folder.name.toLocaleLowerCase().includes(this.args.filter.toLocaleLowerCase());
    }
    @action
    songMatchesFilter(song1: SongFile) {
        return this.filteredSongs.includes(song1);
    }
    get filteredSongs() {
        if (this.args.filter === '') {
            return this.folder.songs;
        }
        return this.folder.songs.filter((song1)=>song1.name.toLocaleLowerCase().includes(this.args.filter.toLocaleLowerCase()));
    }
    get folder(): SongFolder {
        return this.args.folder;
    }
    get isCurrent() {
        return this.folder == this.projectManager.currentFolder;
    }
    @action
    toggleOpenClose() {
        this._isOpen = !this.isOpen;
    }
    @action
    renameFolder(folder1: SongFolder, name1: string) {
        if (name1 === folder1.name) {
            return;
        }
        this.undoManager.executeCommand(new RenameFolderCommand(folder1, name1));
    }
    @action
    renameSong(song1: SongFile, name1: string) {
        if (name1 === song1.name) {
            return;
        }
        this.undoManager.executeCommand(new RenameSongCommand(song1.song!, name1));
    }
    @action
    deleteSong(song1: SongFile) {
        const command1 = new DeleteSongCommand(this.folder, song1);
        command1.on('execute', ()=>{
            this.router.transitionTo('restricted.songs.folder');
        });
        command1.on('reverse', (song1)=>{
            this.router.transitionTo('restricted.songs.folder.song', song1.path);
        });
        this.undoManager.executeCommand(command1);
    }
    @action
    duplicateSong(song1: SongFile) {
        const command1 = new DuplicateSongCommand(this.projectManager, this.folder, song1);
        const folder1 = this.folder;
        command1.on('execute', (newSong1)=>{
            this.router.transitionTo('restricted.songs.folder.song', folder1, newSong1.path);
        });
        command1.on('reverse', (song1)=>{
            this.router.transitionTo('restricted.songs.folder.song', folder1, song1.path);
        });
        this.undoManager.executeCommand(command1);
    }
    @action
    sortSongs(folder1: SongFolder, oldIndex1: number, newIndex1: number) {
        // eslint-disable-next-line ember/no-runloop
        next(()=>{
            this.undoManager.executeCommand(new SortSongsCommand(folder1, oldIndex1, newIndex1));
        });
    }
    @action
    moveSong(song1: SongFile, toFolder1: SongFolder) {
        const fromIndex1 = this.folder.songs.indexOf(song1);
        const command1 = new MoveSongCommand(this.folder, fromIndex1, toFolder1);
        command1.on('execute', (newSong1)=>{
            this.router.transitionTo('restricted.songs.folder.song', toFolder1.path, newSong1.path);
        });
        command1.on('reverse', (song1)=>{
            this.router.transitionTo('restricted.songs.folder.song', song1.folder.path, song1.path);
        });
        this.undoManager.executeCommand(command1);
    }
    @action
    addToPlaylist(playlist1: Playlist, song1: SongFile) {
        const command1 = new AddSongToPlaylistCommand(playlist1, song1);
        this.undoManager.executeCommand(command1);
    }
    @action
    async addToNewPlaylist(song1: SongFile) {
        const playlist1 = await this.undoManager.executeCommand(new AddPlaylistCommand(this.projectManager));
        this.undoManager.appendCommand(new AddSongToPlaylistCommand(playlist1, song1));
    }
    @action
    exportSong(song1: SongFile) {
        this.projectManager.exportSong(song1);
    }
    @action
    showMenuFor(item1: SongFile | SongFolder | undefined, ev1?: MouseEvent) {
        if (item1 && this.menuSong != item1 && this.menuFolder != item1) {
            ev1?.preventDefault();
            ev1?.stopPropagation();
        }
        if (!item1) {
            this.menuSong = undefined;
            this.menuFolder = undefined;
            return;
        }
        if (item1 instanceof SongFile) {
            this.menuSong = item1;
        } else {
            this.menuFolder = item1;
        }
    }
    @action
    shouldShowMenuFor(item1: SongFile | SongFolder) {
        return this.menuSong === item1 || this.menuFolder === item1;
    }
    @action
    deleteFolder(folder1: SongFolder) {
        const command1 = new DeleteSongFolderCommand(this.projectManager, folder1);
        command1.on('execute', ()=>{
            this.router.transitionTo('restricted.songs');
        });
        command1.on('reverse', (folder1)=>{
            this.router.transitionTo('restricted.songs.folder', folder1.path);
        });
        this.undoManager.executeCommand(command1);
    }
    @action
    exportFolder(folder1: SongFolder) {
        this.projectManager.exportFolder(folder1);
    }
    static{
        template(`
    <div class="bg-white dark:bg-primary-darkComponent rounded {{unless this.matchesFilter 'hidden'}}" ...attributes>
      {{! template-lint-disable no-invalid-interactive}}
      <div class="flex items-center justify-between group/folder dark:selected:bg-primary-darkButtonSelected dark:selected:text-black">
        <SelectableItem
          @icon={{if this.isOpen "folder-open" "folder"}}
          @model={{this.folder}}
          @field="name"
          @filter={{nameFilter}}
          @when={{this.isOpen}}
          @onChange={{fn this.renameFolder this.folder}}
          title={{this.folder.name}}
          class="folder-name bg-white"
          {{selected when=this.isOpen}}
          {{on "click" this.toggleOpenClose}}
          {{scrollIntoView when=this.isOpen}}
        >{{add @folderIndex 1}}. {{this.folder.name}}</SelectableItem>
        <OptionButton
          id={{concat "folder-menu-" this.folder.id}}
          class="invisible text-black group-hover/folder:visible selected:visible dark:text-white dark:group-hover/folder:text-white dark:selected:group-hover/folder:text-white"
          {{selected when=this.isOpen}}
          {{on "click" (fn this.showMenuFor this.folder)}}
        />
        {{#if (this.shouldShowMenuFor this.folder)}}
          <nav
            aria-label="Folder menu"
            class="absolute z-50 flex flex-col bg-white border rounded shadow-lg dark:bg-primary-darkComponent"
            {{velcro
              (htmlId (concat "folder-menu-" this.folder.id))
              placement="left-start"
            }}
            {{onClickOutside (fn this.showMenuFor undefined)}}
          >
            <MenuButton
              @icon="download"
              {{on
                "click"
                (queue (fn this.exportFolder this.folder) (fn this.showMenuFor undefined))
              }}
              title="Export folder"
            >Export</MenuButton>
            <MenuButton
              @icon="trash-can"
              title="Delete folder"
              {{on
                "click"
                (queue (fn this.deleteFolder this.folder) (fn this.showMenuFor undefined))
              }}
            >Delete</MenuButton>
          </nav>
        {{/if}}
      </div>
      <div
        data-folder-index={{@folderIndex}}
        class={{if this.isOpen "block pl-4" "hidden"}}
        {{sortable
          draggable=".song-file"
          group="songs"
          swapThreshold=0.65
          onChange=(fn this.sortSongs this.folder)
          onMove=@onMoveSong
        }}
      >
        {{~#if this.isOpen~}}
          {{~#each this.folder.songs as |song songIndex|~}}
            {{#if (or this.folderMatchesFilter (this.songMatchesFilter song))}}
              <div
                class="flex items-center gap-2 overflow-hidden rounded song-file selected:bg-primary selected:text-white group/options dark:selected:bg-primary-darkButtonSelected dark:selected:text-black"
                {{selected when=(eq @selectedSong song)}}
                {{on "click" (fn @onSelectSong this.folder song)}}
              >
                <SelectableItem
                  @icon="music"
                  @model={{song}}
                  @field="name"
                  @filter={{nameFilter}}
                  @when={{eq @selectedSong song}}
                  @onChange={{fn this.renameSong song}}
                  title={{song.name}}
                  {{selected when=(eq @selectedSong song)}}
                >{{add songIndex 1}}. {{song.name}}</SelectableItem>

                <div
                  class="relative invisible text-black group-hover/options:visible selected:visible"
                  {{selected when=(eq @selectedSong song)}}
                >
                  <OptionButton
                    id={{concat "song-menu-" song.id}}
                    class="text-white group-hover/options:text-black selected:group-hover/options:text-white dark:text-black dark:group-hover/options:text-white dark:selected:group-hover/options:text-black"
                    {{selected when=(eq @selectedSong song)}}
                    {{on "click" (fn this.showMenuFor song)}}
                  />
                  {{#if (this.shouldShowMenuFor song)}}
                    <nav
                      aria-label="Song menu"
                      class="fixed z-50 flex flex-col bg-white border rounded shadow-lg dark:bg-primary-darkComponent dark:border-primary-darkBorderColor"
                      {{velcro
                        (htmlId (concat "song-menu-" song.id))
                        placement="left-start"
                      }}
                      {{onClickOutside (fn this.showMenuFor undefined)}}
                    >
                      <div class="relative group/move">
                        <MenuButton @icon="folder-arrow-down">Move to…</MenuButton>
                        <div
                          class="absolute top-0 z-50 flex flex-col invisible overflow-auto bg-white border rounded shadow-lg left-full min-w-max group-hover/move:visible"
                          {{ensureInView}}
                        >
                          {{#each this.projectManager.songFolders as |folder|}}
                            {{#if (notEq folder this.folder)}}
                              <MenuButton
                                @icon="folder"
                                {{on
                                  "click"
                                  (queue
                                    (fn this.moveSong song folder)
                                    (fn this.showMenuFor undefined)
                                  )
                                }}
                              >
                                {{folder.name}}
                              </MenuButton>
                            {{/if}}
                          {{/each}}
                        </div>
                      </div>
                      <div class="relative group/playlist">
                        <MenuButton @icon="folder-music">Add to…</MenuButton>
                        <div
                          class="absolute top-0 z-50 flex flex-col invisible overflow-auto bg-white border rounded shadow-lg left-full min-w-max group-hover/playlist:visible"
                          {{ensureInView}}
                        >
                          {{#each this.projectManager.playlists as |playlist|}}
                            <MenuButton
                              @icon="list-music"
                              {{on
                                "click"
                                (queue
                                  (fn this.addToPlaylist playlist song)
                                  (fn this.showMenuFor undefined)
                                )
                              }}
                            >
                              {{playlist.name}}
                            </MenuButton>
                          {{/each}}
                          {{#if this.projectManager.canAddPlaylist}}
                            <MenuButton
                              @icon="list-music"
                              {{on
                                "click"
                                (queue
                                  (fn this.addToNewPlaylist song)
                                  (fn this.showMenuFor undefined)
                                )
                              }}
                            >
                              New Playlist
                            </MenuButton>
                          {{/if}}
                        </div>
                      </div>
                      <MenuButton
                        @icon="clone"
                        title="Duplicate song"
                        {{on
                          "click"
                          (queue
                            (fn this.duplicateSong song) (fn this.showMenuFor undefined)
                          )
                        }}
                      >Duplicate</MenuButton>
                      <MenuButton
                        @icon="download"
                        {{on
                          "click"
                          (queue
                            (fn this.exportSong song) (fn this.showMenuFor undefined)
                          )
                        }}
                        title="Export song"
                      >Export</MenuButton>
                      <MenuButton
                        @icon="trash-can"
                        title="Delete song"
                        {{on
                          "click"
                          (queue
                            (fn this.deleteSong song) (fn this.showMenuFor undefined)
                          )
                        }}
                      >Delete</MenuButton>
                    </nav>
                  {{/if}}
                </div>
              </div>
            {{/if}}
          {{~else~}}
            <Button @icon="music" class="ml-3" {{on "click" @addSong}}>Add Song</Button>
            <Button @icon="music" class="ml-3" {{on "click" @importSong}}>Import Song</Button>
          {{~/each~}}
        {{~/if~}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Folder: typeof FolderComponent;
    }
}
