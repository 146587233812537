import { action } from '@ember/object';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Command from 'editor/models/command';
import { CompositeCommand } from 'editor/models/command';

export default class UndoManagerService extends Service {
  undoStack: Command<any>[] = [];
  redoStack: Command<any>[] = [];

  @tracked dirtyPoint = 0;
  @tracked undoLevel = 0;
  @tracked redoLevel = 0;

  constructor(properties?: object) {
    super(properties);
    document.addEventListener('keydown', (event) => {
      // redo
      if (
        event.key === 'z' &&
        event.shiftKey &&
        (event.ctrlKey || event.metaKey)
      ) {
        this.redo();
        return;
      }

      //undo
      if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
        this.undo();
        return;
      }
    });
  }

  get isDirty() {
    return this.dirtyPoint !== this.undoLevel;
  }

  // Resets everything
  reset() {
    this.undoStack = [];
    this.redoStack = [];
    this.undoLevel = 0;
    this.redoLevel = 0;
    this.dirtyPoint = 0;
  }

  // Resets after a save but still allows undo
  resetDirty() {
    this.dirtyPoint = this.undoStack.length;
  }

  get canUndo() {
    return this.undoLevel > 0;
  }

  get canRedo() {
    return this.redoLevel > 0;
  }

  @action
  undo() {
    const command = this.undoStack.pop();
    if (command) {
      this.undoLevel--;
      command.undo();
      this.redoStack.push(command);
      this.redoLevel++;
    }
  }

  @action
  redo() {
    const command = this.redoStack.pop(); 
    if (command) {
      this.redoLevel--;
      command.perform();
      this.undoStack.push(command);
      this.undoLevel++;
    }
  }

  executeCommand(command: Command<any>) {
    this.redoStack = [];
    this.redoLevel = 0;
    const result = command.perform();
    this.undoStack.push(command);
    this.undoLevel++;
    return result;
  }

  appendCommand(command: Command<any>, standAloneCommand = true) {
    const lastCommand = this.undoStack.pop();
    if (lastCommand) {
      const compositeCommand = new CompositeCommand(lastCommand.name, [
        lastCommand,
        command,
      ]);
      command.perform();
      this.undoStack.push(compositeCommand);
    } else {
      if (standAloneCommand) {
        this.executeCommand(command);
      }
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'undo-manager': UndoManagerService;
  }
}
