import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
import eq from 'editor/helpers/eq';
import version from 'editor/helpers/version';
import type ProjectManagerService from 'editor/services/project-manager';
import type { DrumsetFile, SongFile } from 'editor/services/project-manager';
import StatusService from 'editor/services/status';
import fileSize from 'editor/utils/file-size';
interface DrumsetStatusSignature {
    Element: HTMLDivElement;
    Args: {
        drumsetFile: DrumsetFile;
    };
    Blocks: {
    };
}
let DrumsetFileStatus = class DrumsetFileStatus extends Component<DrumsetStatusSignature> {
    constructor(owner1: unknown, args1: DrumsetStatusSignature['Args']){
        super(owner1, args1);
    }
    get drumset() {
        return this.args.drumsetFile.drumset!;
    }
    drumsetMessage(drumsetFile1: DrumsetFile) {
        let validity1 = drumsetFile1.drumset!.isValid;
        if (validity1 == true) {
            validity1 = '';
        } else {
            validity1 = ` - ${validity1}`;
        }
        return `${drumsetFile1.name} - ${fileSize(drumsetFile1.drumset!.size)}${validity1}`;
    }
    get drumsetSize() {
        return this.args.drumsetFile.drumset!.size;
    }
    static{
        template(`
    {{@drumsetFile.name}}
    -
    {{fileSize this.drumsetSize}}
    {{#if (eq true this.drumset.isValid)}}
      <span class="text-green-500">✓</span>
    {{else}}
      <span class="text-red-500"><FaIcon @icon="triangle-exclamation" />
        {{this.drumset.isValid}}</span>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
interface StatusBarSignature {
    Element: HTMLDivElement;
    Args: {
    };
    Blocks: {
    };
}
export default class StatusBarComponent extends Component<StatusBarSignature> {
    @service
    status: StatusService;
    @service
    projectManager: ProjectManagerService;
    constructor(owner1: unknown, args1: StatusBarSignature['Args']){
        super(owner1, args1);
    }
    get drumsetFile() {
        return this.status.drumsetFile;
    }
    get songFile() {
        return this.status.songFile;
    }
    @action
    songFileMessage(songFile1: SongFile) {
        const folderIndex1 = this.projectManager.songFolders.indexOf(songFile1.folder);
        const channel1 = 0;
        const songIndex1 = songFile1.folder.songs.indexOf(songFile1);
        const folderMSB1 = Math.floor(folderIndex1 / 128);
        const folderLSB1 = folderIndex1 % 128;
        const midiInfo1 = `CC0:${folderMSB1} CC32:${folderLSB1} PC:${songIndex1} {PC${folderMSB1}.${folderLSB1}:${songIndex1}@${channel1}}`;
        return `${songFile1.name} - ${midiInfo1}`;
    }
    drumsetMessage(drumsetFile1: DrumsetFile) {
        let validity1 = drumsetFile1.drumset!.isValid;
        if (validity1 == true) {
            validity1 = '';
        } else {
            validity1 = ` - ${validity1}`;
        }
        return `${drumsetFile1.name} - ${fileSize(drumsetFile1.drumset!.size)}${validity1}`;
    }
    static{
        template(`
    <div class="grid grid-cols-[1fr_max-content_1fr] gap-4">
      <div data-test-left>
        {{#if this.drumsetFile}}
          <DrumsetFileStatus @drumsetFile={{this.drumsetFile}} />
        {{else if this.songFile}}
          {{this.songFileMessage this.songFile}}
        {{else}}
          Welcome to BeatBuddy Manager!
        {{/if}}
      </div>
      <div data-test-version class="text-center border-l border-r border-gray-300 px-4 dark:border-primary-darkBorderColor"><a
          href="https://www.singularsound.com/pages/bbmo-changelog"
          target="_blank"
          rel="noopener noreferrer"
        >{{version}}</a></div>
      <div data-test-right>{{! right }}</div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        StatusBar: typeof StatusBarComponent;
    }
}
