import { template } from "@ember/template-compiler";
import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DragDropContainer from 'editor/components/drag-drop-container';
import eq from 'editor/helpers/eq';
import gt from 'editor/helpers/gt';
import htmlId from 'editor/helpers/html-id';
import not from 'editor/helpers/not';
import or from 'editor/helpers/or';
import pick from 'editor/helpers/pick';
import queue from 'editor/helpers/queue';
import set from 'editor/helpers/set';
import type { Instrument } from 'editor/models/drumset';
import Drumset from 'editor/models/drumset';
import { PartType, SectionType, SongPart } from 'editor/models/song';
import { AddAccentCommand, ImportAccentCommand, RandomiseFillsCommand, RemoveAccentCommand, UpdateAccentVolumeCommand } from 'editor/models/song-commands';
import disabled from 'editor/modifiers/disabled';
import onClickOutside from 'editor/modifiers/on-click-outside';
import selected from 'editor/modifiers/selected';
import type PasteboardService from 'editor/services/pasteboard';
import ProjectManagerService, { DrumsetFile, EffectFile } from 'editor/services/project-manager';
import SongPlayerService from 'editor/services/song-player';
import UndoManagerService from 'editor/services/undo-manager';
import constrainValue from 'editor/utils/constrain-value';
import playInstrument, { getGainNodeForVelocity } from 'editor/utils/play-instrument';
import velcro from 'ember-velcro/modifiers/velcro';
import Button from '../button';
import MenuButton from '../menu-button';
import OptionButton from '../option-button';
import SelectableItem from '../selectable-item';
interface BuildAccentWindowSignature {
    Element: HTMLDivElement;
    Args: {
        // eslint-disable-next-line no-unused-vars
        importAccent: (file: File) => void;
    };
    Blocks: {
    };
}
// Refactor: This shares a lot of code with the instrument import component
let BuildAccentWindow = class BuildAccentWindow extends Component<BuildAccentWindowSignature> {
    @service
    projectManager: ProjectManagerService;
    audioContext: AudioContext;
    drumsetVolume: GainNode;
    @tracked
    selectedDrumset?: DrumsetFile;
    @tracked
    instrumentsLoading = false;
    @tracked
    selectedInstruments: Instrument[] = [];
    @tracked
    velocity: number = 127;
    @tracked
    _name?: string;
    constructor(owner1: unknown, args1: BuildAccentWindowSignature['Args']){
        super(owner1, args1);
        this.audioContext = new AudioContext({
            latencyHint: 'interactive',
            sampleRate: 44100
        });
        this.drumsetVolume = new GainNode(this.audioContext, {
            gain: 1
        });
        this.drumsetVolume.connect(this.audioContext.destination);
    }
    @action
    async selectDrumset(drumsetFile1: DrumsetFile) {
        this.selectedDrumset = drumsetFile1;
        this.instrumentsLoading = true;
        await drumsetFile1.parse().then((drumset1)=>{
            if (drumset1) {
                drumset1.prepareAudio(this.audioContext);
            }
        });
        this.instrumentsLoading = false;
    }
    get availableInstruments() {
        const drumsetPlayableInstruments1 = this.selectedDrumset?.drumset?.playableInstruments || [];
        return drumsetPlayableInstruments1.filter((i1)=>this.selectedInstruments.includes(i1) || !this.selectedInstruments.map((i1)=>i1.id).includes(i1.id));
    }
    @action
    isSelectedInstrument(instrument1: Instrument) {
        return this.selectedInstruments.includes(instrument1);
    }
    @action
    toggleInstrument(instrument1: Instrument, ev1?: MouseEvent) {
        if (ev1?.shiftKey) {
            const firstSelectedInstrument1 = this.selectedInstruments.at(0);
            const lastSelectedInstrument1 = this.selectedInstruments.at(-1);
            if (firstSelectedInstrument1 && lastSelectedInstrument1) {
                const firstIndex1 = this.availableInstruments.indexOf(firstSelectedInstrument1);
                const lastIndex1 = this.availableInstruments.indexOf(lastSelectedInstrument1);
                const currentIndex1 = this.availableInstruments.indexOf(instrument1);
                if (currentIndex1 < firstIndex1) {
                    this.selectedInstruments = [
                        ...this.selectedInstruments,
                        ...this.availableInstruments.slice(currentIndex1, firstIndex1 + 1)
                    ];
                } else if (currentIndex1 > lastIndex1) {
                    this.selectedInstruments = [
                        ...this.selectedInstruments,
                        ...this.availableInstruments.slice(lastIndex1, currentIndex1 + 1)
                    ];
                } else {
                    this.selectedInstruments = [
                        ...this.selectedInstruments,
                        ...this.availableInstruments.slice(firstIndex1, currentIndex1 + 1)
                    ];
                }
            } else {
                this.selectedInstruments = [
                    instrument1
                ];
            }
        } else {
            if (this.isSelectedInstrument(instrument1)) {
                this.selectedInstruments = this.selectedInstruments.filter((i1)=>i1 != instrument1);
            } else {
                this.selectedInstruments = [
                    ...this.selectedInstruments,
                    instrument1
                ];
            }
        }
        // remove window selection
        window.getSelection()?.removeAllRanges();
    }
    @action
    setVelocity(value1: string) {
        this.velocity = constrainValue(parseInt(value1, 10), 1, 127);
    }
    get name() {
        return (this._name ?? [
            this.selectedDrumset?.name,
            ...this.selectedInstruments.map((i1)=>i1.name),
            this.velocity
        ].join(':'));
    }
    @action
    setName(name1: string) {
        this._name = name1;
    }
    @action
    playAccent() {
        this.audioContext.resume();
        this.selectedInstruments.forEach((instrument1)=>{
            playInstrument(this.audioContext, this.drumsetVolume, instrument1, 0, 0, this.velocity);
        });
    }
    @action
    async addAccent() {
        // Calculate the total time from the longest instrument sample
        const totalTime1 = Math.max(...this.selectedInstruments.map((instrument1)=>{
            let [, sample1] = getGainNodeForVelocity(this.audioContext, this.drumsetVolume, instrument1, this.velocity);
            if (sample1) {
                return sample1.nSample / sample1.nChannel;
            }
            return 0;
        }));
        const audioContext1 = new OfflineAudioContext({
            numberOfChannels: 2,
            length: Math.ceil(totalTime1 / 2),
            sampleRate: 44100
        });
        const gainNode1 = new GainNode(audioContext1, {
            gain: 1
        });
        gainNode1.connect(audioContext1.destination);
        this.selectedInstruments.forEach((instrument1)=>{
            playInstrument(audioContext1, gainNode1, instrument1, 0, 0, this.velocity);
        });
        const audioBuffer1 = await audioContext1.startRendering();
        const fileData1 = Drumset.saveWaveFile(audioBuffer1);
        this.args.importAccent(new File([
            fileData1
        ], `${this.name}.wav`, {
            type: 'audio/wav'
        }));
    }
    static{
        template(`
    <div class="p-4" ...attributes>
      <div
        class="flex flex-col h-full min-h-0 gap-2 overflow-auto"
        data-test-instrument-import
      >
        <h1 class="font-bold text-lg">Build an accent</h1>
        <p>Select a drumset and instruments to build an accent (e.g. Bass drum and crash
          cymbal)</p>
        <div class="flex gap-2 min-h-0 overflow-auto h-full">
          <div class="overflow-auto border-r dark:border-primary-darkBorderColor">
            <div data-test-drumsets class="flex flex-col gap-px h-max">
              {{#each this.projectManager.drumsets as |drumset|}}
                <SelectableItem
                  @icon="drum"
                  @model={{drumset}}
                  @field="name"
                  @when={{false}}
                  title={{drumset.name}}
                  {{selected when=(eq this.selectedDrumset drumset)}}
                  {{on "click" (fn this.selectDrumset drumset)}}
                >{{drumset.name}}</SelectableItem>
              {{/each}}
            </div>
          </div>
          <div class="overflow-auto">
            <div data-test-instruments class="flex flex-col gap-px h-max">
              {{#if this.instrumentsLoading}}
                <div>Loading...</div>
              {{else}}
                {{#each this.availableInstruments as |instrument|}}
                  <SelectableItem
                    @model={{instrument}}
                    @field="name"
                    @when={{false}}
                    title={{instrument.name}}
                    {{selected when=(this.isSelectedInstrument instrument)}}
                    {{on "click" (fn this.toggleInstrument instrument)}}
                  >{{instrument.id}}. {{instrument.name}}</SelectableItem>

                {{else}}
                  <em>Select a drumset to view instruments</em>
                {{/each}}
              {{/if}}
            </div>
          </div>
        </div>
        <div class="flex gap-4 w-full justify-between">
          <label class="flex items-center gap-2">Velcity
            <input
              type="range"
              min="1"
              max="127"
              value={{this.velocity}}
              class="accent-primary w-60"
              {{on "input" (pick "target.value" this.setVelocity)}}
            />
          </label>
          <label class="flex gap-4 items-center">Name:
            <input
              type="text"
              value={{this.name}}
              {{on "input" (pick "target.value" this.setName)}}
              class="w-full border rounded-md"
            /></label>
          <div>
            <Button
              {{disabled when=(eq 0 this.selectedInstruments.length)}}
              @icon="play"
              {{on "click" this.playAccent}}
            />
            <Button
              {{disabled when=(eq 0 this.selectedInstruments.length)}}
              class="bg-primary text-white"
              {{on "click" this.addAccent}}
            >Add accent</Button>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
interface AddAccentButtonSignature {
    Element: HTMLDivElement;
    Args: {
        // eslint-disable-next-line no-unused-vars
        addAccent: (effect: EffectFile) => void;
        // eslint-disable-next-line no-unused-vars
        importAccent: (files: FileList | File[]) => void;
        // eslint-disable-next-line no-unused-vars
        playAccent: (part: SongPart) => void;
    };
    Blocks: {
    };
}
let AddAccentButton = class AddAccentButton extends Component<AddAccentButtonSignature> {
    @service
    projectManager: ProjectManagerService;
    @service
    songPlayer: SongPlayerService;
    @service
    undoManager: UndoManagerService;
    @tracked
    showAccents = false;
    @tracked
    showBuildAccentWindow = false;
    elementId = guidFor(this);
    get sortedEffects() {
        return this.projectManager.effects.toSorted((e11, e21)=>e11.name.localeCompare(e21.name));
    }
    @action
    toggleShowAccents() {
        this.showAccents = !this.showAccents;
    }
    @action
    toggleShowBuildAccentWindow() {
        this.showBuildAccentWindow = !this.showBuildAccentWindow;
    }
    @action
    addAccent(effect1: EffectFile) {
        this.args.addAccent(effect1);
    }
    @action
    importAccent(file1: File) {
        this.args.importAccent([
            file1
        ]);
    }
    @action
    playEffect(effect1: EffectFile) {
        this.songPlayer.playAccent(effect1, 100);
    }
    @action
    popupMiddleware() {
        return [
            // Calculates the maximum height for the accent popup list
            {
                name: 'Max height',
                options: {},
                async fn (state1: any) {
                    const availableHeight1 = document.documentElement.clientHeight;
                    const top1 = state1.rects.reference.y + state1.rects.reference.height;
                    const height1 = availableHeight1 - top1 - 20;
                    state1.elements.floating.style.maxHeight = `${height1}px`;
                    return state1;
                }
            }
        ];
    }
    static{
        template(`
    <div class="relative w-full">
      <Button
        @icon="plus"
        id={{this.elementId}}
        class="w-full"
        title="Select an accent for this part"
        {{on "click" this.toggleShowAccents}}
      >
        accent
      </Button>
      {{#if this.showAccents}}
        <div
          class="absolute z-40 flex flex-col overflow-auto bg-white border rounded shadow-lg"
          {{velcro (htmlId this.elementId) middleware=(this.popupMiddleware)}}
          {{onClickOutside
            (queue
              (set this "showBuildAccentWindow" false) (set this "showAccents" false)
            )
          }}
        >
          {{#if this.showBuildAccentWindow}}
            <BuildAccentWindow
              @importAccent={{this.importAccent}}
              class="fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4 h-3/4 bg-white border rounded shadow-lg dark:bg-primary-darkFill dark:text-white dark:border-primary-darkBorderColor"
            />
          {{/if}}
          <div
            class="flex gap-1 p-1 bg-white border-t first-of-type:border-none hover:bg-gray-100"
          >
            <Button
              @icon="drum"
              class="px-2 py-1 text-left text-sm grow border-0"
              {{on "click" this.toggleShowBuildAccentWindow}}
            >Build an accent</Button>
          </div>
          {{#each this.sortedEffects as |effect|}}
            <div
              class="flex items-start justify-center gap-1 p-1 bg-white border-t first-of-type:border-none hover:bg-gray-100"
            >
              <Button
                @icon="play"
                class="px-2 py-1 m-auto bg-white hover:bg-primary"
                {{on "click" (fn this.playEffect effect)}}
              />
              <MenuButton
                type="button"
                class="px-2 py-1 text-left text-sm grow !border-b-0"
                {{on "click" (fn this.addAccent effect)}}
              >
                {{effect.name}}
              </MenuButton>
            </div>
          {{/each}}
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
interface VolumeButtonSignature {
    Element: HTMLDivElement;
    Args: {
        part: SongPart;
        // eslint-disable-next-line no-unused-vars
        playAccent: (part: SongPart) => void;
    };
    Blocks: {
    };
}
let VolumeButton = class VolumeButton extends Component<VolumeButtonSignature> {
    @service
    undoManager: UndoManagerService;
    @tracked
    showVolume = false;
    get part() {
        return this.args.part;
    }
    get volumeIcon() {
        if (this.part.accentVolume < 90) {
            return 'volume-low';
        } else if (this.part.accentVolume > 110) {
            return 'volume-high';
        } else {
            return 'volume';
        }
    }
    @action
    resetVolume() {
        this.changeVolume(100);
    }
    @action
    async changeVolume(volume1: number) {
        await this.undoManager.executeCommand(new UpdateAccentVolumeCommand(this.part, volume1));
        this.args.playAccent(this.part);
    }
    @action
    toggleShowVolume(ev1: Event) {
        ev1.stopPropagation();
        this.showVolume = !this.showVolume;
    }
    @action
    noop(ev1: Event) {
        ev1.stopPropagation();
        ev1.stopImmediatePropagation();
    }
    static{
        template(`
    <label
      class="relative px-2 py-1 border rounded"
      {{on "dblclick" this.resetVolume}}
      {{on "click" this.toggleShowVolume}}
      {{onClickOutside (set this "showVolume" false)}}
    >
      <FaIcon @icon={{this.volumeIcon}} @fixedWidth={{true}} />
      {{#if this.showVolume}}
        <div class="absolute z-50 -rotate-90 -translate-y-1/2 top-1/2">
          <input
            type="range"
            min="0"
            max="200"
            class="w-20 accent-primary"
            value={{@part.accentVolume}}
            {{on "click" this.noop}}
            {{on "change" (pick "target.value" this.changeVolume)}}
          />
        </div>
      {{/if}}
    </label>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
interface SongPlayerPartSignature {
    Element: HTMLDivElement;
    Args: {
        type: PartType;
        part: SongPart;
        name: string;
        // eslint-disable-next-line no-unused-vars
        addPart?: (ev: MouseEvent) => void;
        // eslint-disable-next-line no-unused-vars
        removePart?: (part: SongPart, ev: MouseEvent) => void;
        // eslint-disable-next-line no-unused-vars
        duplicatePart?: (part: SongPart, ev: MouseEvent) => void;
        // eslint-disable-next-line no-unused-vars
        pastePart?: (part: SongPart, ev: MouseEvent) => void;
        // eslint-disable-next-line no-unused-vars
        addTrack: (sectionType: SectionType) => void;
        // eslint-disable-next-line no-unused-vars
        selectTrack: (sectionType: SectionType, index: number) => void;
        // eslint-disable-next-line no-unused-vars
        importMidi: (sectionType: SectionType, index: number, files: File[]) => void;
    };
    Blocks: {
    };
}
export default class SongPlayerPartComponent extends Component<SongPlayerPartSignature> {
    @service
    songPlayer: SongPlayerService;
    @service
    projectManager: ProjectManagerService;
    @service
    undoManager: UndoManagerService;
    @service
    pasteboard: PasteboardService;
    @tracked
    showMenu = false;
    constructor(owner1: unknown, args1: SongPlayerPartSignature['Args']){
        super(owner1, args1);
    }
    elementId = guidFor(this);
    get part(): SongPart {
        return this.args.part;
    }
    get partSupportsFills() {
        return this.args.type === PartType.PART;
    }
    get canAddFill() {
        return this.partSupportsFills && this.part.fills.length < 8;
    }
    get canAddMain() {
        return !this.part.main;
    }
    get partSupportsTransition() {
        return this.args.type === PartType.PART;
    }
    get canAddTransition() {
        return this.partSupportsTransition && !this.part.transition;
    }
    get partSupportsAccents() {
        return this.args.type === PartType.PART;
    }
    get canAddAccent() {
        return this.partSupportsAccents && !this.part.hasAccent;
    }
    @action
    accentName(part1: SongPart) {
        return this.projectManager.findEffect(part1.accentFileName)?.name;
    }
    @action
    async playAccent(part1: SongPart) {
        const accent1 = this.projectManager.findEffect(part1.accentFileName);
        if (accent1) {
            this.songPlayer.playAccent(accent1, part1.accentVolume);
        }
    }
    @action
    removeAccent(part1: SongPart) {
        this.undoManager.executeCommand(new RemoveAccentCommand(part1));
    }
    @action
    toggleShuffleFills() {
        this.undoManager.executeCommand(new RandomiseFillsCommand(this.part, !this.part.shuffleFills));
    }
    get hasCopiedPart() {
        return this.pasteboard.hasCopy('part');
    }
    @action
    copyPart(part1: SongPart) {
        this.pasteboard.store('part', part1);
    }
    partType(type1: PartType) {
        switch(type1){
            case PartType.INTRO:
                return 'intro';
            case PartType.OUTRO:
                return 'outro';
            case PartType.PART:
                return 'part';
            default:
                throw new Error('Unknown part type');
        }
    }
    @action
    addAccent(effectFile1: EffectFile) {
        this.undoManager.executeCommand(new AddAccentCommand(this.part, effectFile1.path));
    }
    @action
    importAccent(files1: FileList | File[]) {
        if (files1.length > 0) {
            const file1 = files1[0]!;
            if (file1.type !== 'audio/wav') {
                console.error('Invalid file type');
                return;
            }
            const command1 = new ImportAccentCommand(this.projectManager, file1);
            command1.on('execute', (effectFile1)=>{
                this.undoManager.appendCommand(new AddAccentCommand(this.part, effectFile1.path));
            });
            this.undoManager.executeCommand(command1);
        }
    }
    @action
    importMidi(sectionType1: SectionType, index1: number, files1: FileList) {
        const midiFiles1 = Array.from(files1).filter((file1)=>file1.type === 'audio/midi' || file1.type === 'audio/mid');
        if (midiFiles1.length === 0) {
            console.error('No MIDI files found');
            return;
        }
        this.args.importMidi(sectionType1, index1, midiFiles1);
    }
    static{
        template(`
    <div
      class="grid items-stretch w-full col-span-6 grid-cols-subgrid part dark:bg-primary-darkFill"
      data-part-type={{this.partType @part.type}}
      ...attributes
    >
      <div class="flex items-center font-bold">
        {{#if (eq @part.type PartType.PART)}}<span
            class="h-full -ml-2 drag-handle opacity-30"
          ></span>{{else}}<span class="w-5 -ml-2"></span>{{/if}}
        {{@name}}
      </div>
      <DragDropContainer
        @onDrop={{fn this.importMidi SectionType.MAIN 0}}
        class="flex border border-transparent border-dashed droppable:!border-green-500 dark:border-primary-darkBorderColor"
      >
        {{#if @part.main}}
          <button
            type="button"
            class="w-full px-2 py-1 overflow-hidden text-sm border rounded text-nowrap text-ellipsis bg-primary-light selected:bg-primary selected:text-white dark:bg-primary-darkSample dark:border-primary-darkBorderColor"
            title={{@part.main.name}}
            {{selected when=(eq this.songPlayer.track @part.main)}}
            {{on "click" (fn @selectTrack SectionType.MAIN 0)}}
          >
            {{@part.main.name}}
          </button>
        {{/if}}
        {{#if this.canAddMain}}
          <Button
            @icon="plus"
            title="Add track"
            class="w-full"
            {{on "click" (fn @addTrack SectionType.MAIN)}}
          >track</Button>
        {{/if}}
      </DragDropContainer>
      <DragDropContainer
        @onDrop={{fn this.importMidi SectionType.FILL @part.fills.length}}
        @isActive={{this.canAddFill}}
        class="flex justify-end gap-2 border border-transparent border-dashed droppable:!border-green-500"
      >
        {{#each @part.fills as |fill i|}}
          <button
            type="button"
            class="w-full px-2 py-1 overflow-hidden text-sm border rounded text-nowrap text-ellipsis bg-primary-light selected:bg-primary selected:text-white dark:bg-primary-darkSample dark:border-primary-darkBorderColor"
            title={{fill.name}}
            {{selected when=(eq this.songPlayer.track fill)}}
            {{on "click" (fn @selectTrack SectionType.FILL i)}}
          >
            {{fill.name}}
          </button>
        {{/each}}
        {{#if (gt @part.fills.length 1)}}
          <Button
            @icon="shuffle"
            title="Randomise fills"
            {{selected when=@part.shuffleFills}}
            {{on "click" this.toggleShuffleFills}}
          />
        {{/if}}
        {{#if this.canAddFill}}
          <Button
            @icon="plus"
            disabled={{eq @part.fills.length 8}}
            {{on "click" (fn @addTrack SectionType.FILL)}}
          />
        {{/if}}
      </DragDropContainer>
      <DragDropContainer
        @onDrop={{fn this.importMidi SectionType.TRANSITION 0}}
        @isActive={{this.partSupportsTransition}}
        class="flex border border-transparent border-dashed droppable:!border-green-500"
      >
        {{#if @part.transition}}
          <button
            type="button"
            class="w-full px-2 py-1 overflow-hidden text-sm border rounded text-nowrap text-ellipsis btn bg-primary-light selected:bg-primary selected:text-white dark:bg-primary-darkSample dark:border-primary-darkBorderColor"
            title={{@part.transition.name}}
            {{selected when=(eq this.songPlayer.track @part.transition)}}
            {{on "click" (fn @selectTrack SectionType.TRANSITION 0)}}
          >
            {{@part.transition.name}}
          </button>
        {{/if}}
        {{#if this.canAddTransition}}
          <Button
            @icon="plus"
            title="Add transition"
            class="w-full"
            {{on "click" (fn @addTrack SectionType.TRANSITION)}}
          >transition</Button>
        {{/if}}
      </DragDropContainer>
      <DragDropContainer
        @onDrop={{this.importAccent}}
        @isActive={{this.partSupportsAccents}}
        class="flex gap-2 border border-dashed border-transparent droppable:!border-green-500"
      >
        {{#if @part.hasAccent}}
          <Button
            @icon="play"
            title={{this.accentName @part}}
            {{on "click" (fn this.playAccent @part)}}
          />
          <VolumeButton @part={{@part}} @playAccent={{this.playAccent}} />
          <Button
            @icon="trash-can"
            class="ml-auto"
            {{on "click" (fn this.removeAccent @part)}}
          />
        {{else if this.canAddAccent}}
          <AddAccentButton
            @addAccent={{this.addAccent}}
            @importAccent={{this.importAccent}}
            @playAccent={{this.playAccent}}
          />
        {{/if}}
      </DragDropContainer>
      <div class="flex gap-2">
        {{#if @addPart}}
          <Button @icon="plus" {{on "click" @addPart}} />
        {{/if}}
        {{#if (or @removePart @duplicatePart @pastePart)}}
          <div class="relative">
            <OptionButton
              id={{concat "part-menu-" this.elementId}}
              class="border rounded selected:bg-primary selected:text-white"
              {{selected when=this.showMenu}}
              {{on "click" (set this "showMenu" true)}}
            />
            {{#if this.showMenu}}
              <nav
                class="absolute z-20 flex flex-col bg-white border rounded shadow-lg"
                {{velcro
                  (htmlId (concat "part-menu-" this.elementId))
                  placement="left-start"
                }}
                {{onClickOutside (set this "showMenu" false)}}
              >
                <MenuButton
                  @icon="copy"
                  {{on
                    "click"
                    (queue (fn this.copyPart @part) (set this "showMenu" false))
                  }}
                >Copy</MenuButton>
                {{#if @pastePart}}
                  <MenuButton
                    @icon="paste"
                    {{disabled when=(not this.hasCopiedPart)}}
                    {{on
                      "click"
                      (queue (fn @pastePart @part) (set this "showMenu" false))
                    }}
                  >Paste</MenuButton>
                {{/if}}
                {{#if @duplicatePart}}
                  <MenuButton
                    @icon="clone"
                    {{on
                      "click"
                      (queue (fn @duplicatePart @part) (set this "showMenu" false))
                    }}
                  >Duplicate</MenuButton>
                {{/if}}
                {{#if @removePart}}
                  <MenuButton
                    @icon="trash-can"
                    class="px-2 py-1 text-left hover:bg-gray-100"
                    {{on
                      "click"
                      (queue (fn @removePart @part) (set this "showMenu" false))
                    }}
                  >Delete</MenuButton>
                {{/if}}
              </nav>
            {{/if}}
          </div>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
